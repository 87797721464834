//Libraries
import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";

//Styled-Components
import { Option, StyledSelect } from "./styles";

interface Props {
  optionList: Array<any>;
  width?: string;
  id: string;
  handleChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: FC<Props> = ({ optionList, id, handleChange }) => {
  const { register, getValues, setValue } = useFormContext();

  const hasDisponible = optionList.length && Object.keys(optionList[0]).includes("disponible");

  return (
    <StyledSelect
      id={id}
      {...register(id)}
      value={getValues(id)}
      onChange={(e) => {
        setValue(id, e.target.value);
        if (handleChange) handleChange(e);
      }}
    >
      {optionList.map((option: any, index: number) => {
        const idopt = Object.keys(option)[0];

        if (hasDisponible && !option.disponible) {
          return (
            <Option key={index} value={option[idopt]} disabled>
              {option.nombre}
            </Option>
          );
        }

        return (
          <option key={index} value={option[idopt]}>
            {option.nombre}
          </option>
        );
      })}
    </StyledSelect>
  );
};
