import { FC } from "react";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";

import { enviarFichaCotizacion } from "../../../Providers/FichaCotizacion/FichaCotizacion.provider";

import { Wrapper } from "./styles";

import { AiOutlineMail } from "react-icons/ai";
import { SwalModal } from "../SwalModal/SwalModal";

interface Props {
  email: string;
  idsimulacion: number;
  size?: "lg";
}

export const SendFicha: FC<Props> = ({ email, idsimulacion, size }) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}") || {
    idusuario: 0,
  };
  const idusuario = user.idusuario;

  const handleClick = () => {
    SwalModal().fire({
      title: "Por favor, confirme su correo electrónico",
      input: "email",
      inputValue: email, // default value
      inputAttributes: {
        style: "color: black; box-shadow: none;",
      },
      validationMessage: "Correo invalido",
      confirmButtonText: "Enviar",
      confirmButtonColor: "#ffd135",
      confirmButtonAriaLabel: "Enviar",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      preConfirm: sendMail,
    });
  };

  const sendMail = async (correo: string) => {
    try {
      const res = await enviarFichaCotizacion({
        idusuario,
        idsimulacion,
        correo,
      });

      SwalModal().fire({
        title: "Correo enviado",
        text: res.message,
        icon: "success",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#ffd135",
        confirmButtonAriaLabel: "Aceptar",
      });
    } catch (error: any) {
      const { message } = error.response.data;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: message || "Error al enviar correo",
      });
    }
  };

  return (
    <Tippy content="Enviar por correo">
      <Wrapper onClick={handleClick} size={size}>
        <AiOutlineMail size={23} color="000000" />
      </Wrapper>
    </Tippy>
  );
};
