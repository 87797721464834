import { FC } from "react";
import { Routes as Switch, Route, useNavigate } from "react-router-dom";

import { AiFillCloseCircle, AiOutlineSetting } from "react-icons/ai";

import {
  Body,
  Close,
  Description,
  Header,
  HeaderContent,
  Title,
} from "./styles";

import { NuevaPassword } from "../NuevaPassword/NuevaPassword";
import { ActualizarDatos } from "../ActualizarDatos/ActualizarDatos";
import { MenuUsuario } from "../MenuUsuario/MenuUsuario";
import { ModalLayout } from "../../Molecules/ModalLayout/ModalLayout";

interface Props {
  setIsOpenModal: (value: boolean) => void;
}

export const ModalMenu: FC<Props> = ({ setIsOpenModal }) => {
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpenModal(false);
    navigate("", { replace: false });
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <Header>
        <AiOutlineSetting size={38} color="#6c6c6c" />
        <HeaderContent>
          <Title>Mi panel de Control</Title>
          <Description>
            Bienvenido a su panel de control, acá podrá cambiar sus datos de
            cliente Progreso.
          </Description>
        </HeaderContent>
        <Close onClick={closeModal}>
          <AiFillCloseCircle size={28} color={"#F7CF46"} />
        </Close>
      </Header>
      <Body>
        <Switch>
          <Route path="/" element={<MenuUsuario />} />
          <Route path="/changepassword" element={<NuevaPassword />} />
          <Route path="/updateprofile" element={<ActualizarDatos />} />
        </Switch>
      </Body>
    </ModalLayout>
  );
};
