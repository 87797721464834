import { AxiosResponse } from "axios";

import {
  CreateUsuario,
  UpdateUsuario,
  Usuario,
} from "../../Interfaces/Usuario.interface";

import { instance } from "../api";

type GetMainResponse = AxiosResponse<{ usuario: Usuario }>;

export const getMainUsuario = async (id: number): Promise<GetMainResponse> => {
  const { data } = await instance.get(`Usuarios/main/${id}`);
  return data;
};

export const updatePassword = async (body: {
  idusuario: number;
  password: string;
  new_password: string;
}): Promise<AxiosResponse> => {
  const { data } = await instance.put(`Usuarios/actualizarPassword`, body);
  return data;
};

export const updateUser = async (body: {
  idusuario: number;
  nombre: string;
  celular: string;
}) => {
  const { data } = await instance.put(`Usuarios/actualizarDatos`, body);
  return data;
};

export const getTokenTemporal = async (): Promise<AxiosResponse> => {
  const { data } = await instance.get(`Usuarios/tokenTemporal`);
  return data;
};

export const sendUrlToMail = async (
  body: {
    correo: string;
    captchatoken: string;
  },
  tokenRequested: string
): Promise<AxiosResponse> => {
  const { data } = await instance.post(`Usuarios/recuperarPassword`, body, {
    headers: { Authorization: `Bearer ${tokenRequested}` },
  });
  return data;
};

export const resetPassword = async (
  body: {
    correo: string;
    password: string;
    token: string;
  },
  tokenRequested: string
): Promise<AxiosResponse> => {
  const { data } = await instance.put(`Usuarios/resetPassword`, body, {
    headers: { Authorization: `Bearer ${tokenRequested}` },
  });
  return data;
};

interface Props {
  rut?: string;
  idproveedor?: number;
  estado?: number;
  tipo?: number;
}

export const usersBandeja = async (
  iduser: number,
  props?: Props
): Promise<AxiosResponse> => {
  const { data } = await instance.get(`Usuarios/bandeja/${iduser}`, {
    params: {
      rut: props?.rut,
      idproveedor: props?.idproveedor,
      estado: props?.estado,
      tipousuario: props?.tipo,
    },
  });
  return data;
};

export const proveedoresCombo = async (): Promise<AxiosResponse> => {
  const { data } = await instance.get(`Proveedores/Combo`);
  return data;
};

export const createUsuario = async (
  body: CreateUsuario
): Promise<AxiosResponse> => {
  const { data } = await instance.post(`Usuarios/Registrar`, body);
  return data;
};

export const activarUsuario = async (
  idusuario: number
): Promise<AxiosResponse> => {
  const { data } = await instance.put(`Usuarios/Activar/${idusuario}`);
  return data;
};

export const desactivarUsuario = async (
  idusuario: number
): Promise<AxiosResponse> => {
  const { data } = await instance.put(`Usuarios/Desactivar/${idusuario}`);
  return data;
};

export const getUsuarioById = async (
  idusuario: number
): Promise<AxiosResponse> => {
  const { data } = await instance.get(`Usuarios/Obtener/${idusuario}`);
  return data;
};

export const updateUsuario = async (
  body: UpdateUsuario
): Promise<AxiosResponse> => {
  const { data } = await instance.put(`Usuarios/AdminActualizaDatos`, body);
  return data;
};
