export const useFormatNumbers = () => {
  function results(value: string) {
    let val = value;
    //123.100,00
    let dec = value.substring(value.length - 3, value.length);
    if (dec.includes(".")) {
      dec = dec.replace(".", ",");
      val = val.substring(0, value.length - 3) + dec;
    }
    const decimals = val.split(",");
    const valueNormal = parseFloat(decimals[0].replaceAll(".", ""));
    //123100.00
    const numberFormated = parseFloat(
      valueNormal + (decimals.length > 1 ? `.${decimals[1]}` : "")
    ).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const format =
      numberFormated.split(",")[0] +
      (decimals.length > 1 ? "," + decimals[1].substring(0, 2) : "");
    const real = parseFloat(format.replaceAll(".", "").replaceAll(",", "."));

    return { format, real };
  }

  return { results };
};
