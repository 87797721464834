import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

//Components
import { ModalMenu } from '../../Organisms/ModalMenu/ModalMenu'
import { Icon } from '../../Atoms/Icon/Icon'
import { Text } from '../../Atoms/Text/Text'

//Styled Components
import { TextContainer, Wrapper } from './styles'

//Assets
import { IoPersonOutline } from 'react-icons/io5'
import Tippy from '@tippyjs/react'

export const UserInfo = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { nombres } = useSelector((state: any) => state.main)
  const navigate = useNavigate()

  const sayHello = `Bienvenido, ${nombres}`

  //const company = "VOLVO";
  const job = 'Ejecutivo'
  const jobTitle = `${job}`

  return (
    <Wrapper>
      <TextContainer>
        <Text color="#ffffff" fontWeight="bold" margin="0">
          {sayHello}
        </Text>

        <Text color="#ffffff" fontWeight="normal" fontSize="0.8rem" margin="0">
          {jobTitle}
        </Text>
      </TextContainer>

      <Tippy content="Mi panel de Control">
        <div>
          <Icon
            icon={<IoPersonOutline />}
            padding="0.3rem"
            border="2px solid #ffffff"
            onClick={() => {
              setIsOpenModal(true)
              navigate('')
            }}
            pointer
          />
        </div>
      </Tippy>

      {isOpenModal && <ModalMenu setIsOpenModal={setIsOpenModal} />}
    </Wrapper>
  )
}
