import { SET_COTIZAR } from "../constants/cotizar";

interface Action {
  type: string;
  payload: {
    nombre: string;
    rut: string;
    correo: string;
  };
}

interface ClienteState {
  nombre: string;
  rut: string;
  correo: string;
  bien: string;
  marca: number;
  modelo: string;
  anho: string;
  cantidad: string;
  moneda: string;
}

const initialState: ClienteState = {
  nombre: "",
  rut: "",
  correo: "",
  bien: "",
  marca: 0,
  modelo: "",
  anho: "",
  cantidad: "",
  moneda: "",
};

export function clienteReducer(
  state: ClienteState = initialState,
  action: Action
) {
  switch (action.type) {
    case SET_COTIZAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
