import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    li {
      padding-right: 2rem;
      font-size: 14px;
    }
    h2 {
      font-size: 18px;
      margin-left: 10px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 21px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Item = styled.li`
  color: #2a2a2a;
`;
