import styled from "styled-components";

export const Container = styled.div`
  width: 360px;
`;

export const Form = styled.form`
  display: grid;
  place-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
`;
