//
import { FC } from 'react'

//
import { Input } from '../../Atoms/Input/Input'
import { Dropdown } from '../Dropdown/Dropdown'
import { RutInput } from '../RutInput/RutInput'

//
import { Label, Wrapper } from './styles'

interface Props {
  id: string
  label: string
  type?: string
  max?: string
  min?: string
  promt?: string
  zIndex?: number
  options?: Array<any>
  handleChange?: (e: any) => void
}

export const FieldFiltro: FC<Props> = ({
  id,
  max,
  min,
  promt,
  type,
  zIndex,
  label,
  options,
  handleChange,
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {type === 'dropdown' && options ? (
        <Dropdown
          promt={promt}
          options={options}
          handleChange={handleChange}
          width={'238px'}
          zindex={zIndex}
        />
      ) : type === 'rut' ? (
        <RutInput id={id} width="230px" />
      ) : (
        <Input
          id={id}
          width="230px"
          type={type}
          max={max}
          min={min}
          maxLength={id === 'idsimulacion' ? 5 : undefined}
        />
      )}
    </Wrapper>
  )
}
