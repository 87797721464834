//Libraries
import { useDispatch } from 'react-redux'

// Components
import { Text } from '../../Atoms/Text/Text'
import { Image } from '../../Atoms/Image/Image'
import { Verticaline } from '../../Atoms/VerticalLine/VerticalLine'

// Styled Components
import { ImageContainer, Wrapper } from './styles'

// Assets
import LogoProgreso from '../../../assets/images/logo_fondooscuro.png'

// Actions
import { RESET_CALCULABLE } from '../../../App/constants/calculable'

export const Logos = () => {
  const dispatch = useDispatch()

  const goHome = () => {
    window.scrollTo(0, 0)
    dispatch({ type: RESET_CALCULABLE })
  }

  return (
    <Wrapper>
      <ImageContainer onClick={goHome}>
        <Image src={LogoProgreso} alt="Logo Progreso" height="90px" />
      </ImageContainer>

      <Verticaline />
      <Text color="#ffffff">Portal de Proveedores</Text>
    </Wrapper>
  )
}
