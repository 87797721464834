//Libraries
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

//Components
import { Field } from '../Field/Field'

//Interfaces
import { IPlazo } from '../../../Interfaces/Plazos.interface'

//Providers
import { getPlazos } from '../../../Providers/Plazos/Plazos.provider'

export const Plazos = () => {
  const [plazos, setPlazos] = useState<IPlazo[]>([])
  const { setValue, getValues } = useFormContext()

  useEffect(() => {
    const fetchPlazos = async () => {
      try {
        const { data } = await getPlazos()
        const { plazo } = getValues()

        setPlazos(data)
        setValue('plazo', plazo || data[0].id)
      } catch (error) {}
    }

    fetchPlazos()

    return () => {
      setPlazos([])
    }
  }, [setValue, getValues])

  const handleChange = (e: any) => {
    setValue('plazo', e.id)
  }

  const getPlazo = () => {
    const { plazo } = getValues()
    const plazoSelected = plazos.find((p) => p.id === plazo)

    return plazoSelected?.nombre
  }
  return (
    <Field
      label="Plazo"
      dropdown={plazos}
      dropdownChange={handleChange}
      promt={getPlazo() ? getPlazo() : '12 meses'}
      id="plazo"
      labelWidth="73px"
    />
  )
}
