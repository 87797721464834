import { Logos } from "../../Molecules/Logos/Logos";
import { UserInfo } from "../../Molecules/UserInfo/UserInfo";
// Components

// Styled Components
import { Wrapper } from "./styles";

//Assets

export const Header = () => {
  return (
    <Wrapper>
      <Logos />
      <UserInfo />
    </Wrapper>
  );
};
