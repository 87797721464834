import { AxiosResponse } from "axios";
import { BienCombo, IBien } from "../../Interfaces/Bien.interface";

import { instance } from "../api";

type BienesResponse = AxiosResponse<BienCombo[]>;

export const getBienesCombo = async (): Promise<BienesResponse> => {
  const { data } = await instance.get("Bienes/Combo");
  return data;
};

type BienResponse = AxiosResponse<IBien>;

export const getBienById = async (id: number): Promise<BienResponse> => {
  const { data } = await instance.get(`Bienes/${id}`);
  return data;
};
