import { useNavigate } from "react-router-dom";
import { Image } from "../../Components/Atoms/Image/Image";

//Layouts
import { Text } from "../../Components/Atoms/Text/Text";
import { LoginForm } from "../../Components/Organisms/LoginForm/LoginForm";
import { LoginLayout } from "../../Layouts/Login";
import { Line } from "../../Layouts/Login/styles";

//Styles
import { Header, Title, Wrapper } from "./styles";

import Logo from "../../assets/images/custom-logo.png";


export const Login = () => {
  const navigate = useNavigate();
  return (
    <LoginLayout>
      <Wrapper>
        <Header>
          <Image src={Logo} alt="Logo" height="35px" />
          <Line style={{ width: "30px" }} />
          <Title>Acceso a Portal de Proveedores</Title>
        </Header>
        <LoginForm />
        <Text
          color="#1D1D1B"
          fontSize="0.7rem"
          fontWeight="bold"
          pointer
          onClick={() => navigate("/misspassword")}
        >
          ¿Olvidaste / quieres cambiar tu contraseña?
        </Text>
      </Wrapper>
    </LoginLayout>
  );
};
