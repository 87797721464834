import { Container, Wrapper } from './styles'

import { usePagination } from '../../../Hooks/usePagination'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'

interface Props<T> {
  dataHeader: string[]
  dataBody: T[]
  actions?: any[]
}

export const Table = <T extends object>({ dataBody, dataHeader, actions }: Props<T>) => {
  const { currentPage, totalPages, paginatedItems, nextPage, prevPage, chosenPage } = usePagination({
    items: dataBody,
    itemsPerPage: 6,
  })

  const renderHeader = () => {
    return (
      <tr>
        {dataHeader.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    )
  }

  const renderBody = () => {
    return paginatedItems.map((body: any, index) => {
      return (
        <tr key={index}>
          {Object.keys(body).map((key: string, i) => {
            if (key === 'estado') return <td key={key}>{body[key] === 1 ? 'Activo' : 'Inactivo'}</td>
            return <td key={key + i}>{body[key]}</td>
          })}
          <td style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            {actions &&
              actions.map((action: any, i) => (
                <div key={action.label + i} onClick={() => action.action(body)}>
                  {action.label}
                </div>
              ))}
          </td>
        </tr>
      )
    })
  }

  const renderPagination = () => {
    return (
      <div className="pagination">
        <button onClick={prevPage} style={{ backgroundColor: 'transparent' }}>
          <MdOutlineKeyboardArrowLeft size={18} />
        </button>
        <div className="numbers">
          {currentPage - 3 >= 1 && <>...</>}

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => chosenPage(i + 1)}
              style={{
                background: currentPage === i + 1 ? '#F7CF46' : 'transparent',
                borderRadius: '50%',
                paddingRight: '13px',
                paddingLeft: '13px',
              }}
            >
              {i + 1}
            </button>
          ))}
          {currentPage + 3 <= totalPages && <>...</>}
        </div>
        <button onClick={nextPage} style={{ backgroundColor: 'transparent' }}>
          <MdOutlineKeyboardArrowRight size={18} />
        </button>
      </div>
    )
  }

  return (
    <Container>
      <Wrapper>
        <thead>{renderHeader()}</thead>
        <tbody>{renderBody()}</tbody>
      </Wrapper>
      {renderPagination()}
    </Container>
  )
}
