import { isArray } from "lodash";
import { useFormatNumbers } from "../Hooks/useFormatNumbers";
import { getUf } from "../Providers/Uf/Uf.provider";

export const fromXtoUF = async (idmoneda: number, total: number) => {
  const { data } = await getUf(idmoneda, total);
  const { valor_total } = data;
  let aea;
  if (String(valor_total).includes(".")) aea = Number(valor_total.toFixed(2));
  return aea ? aea : valor_total;
};

export const TotalAccesorios = (accesorios: any) => {
  const { results } = useFormatNumbers();

  if (!isArray(accesorios) || !accesorios || accesorios.length === 0) return 0;

  const reducer = (previousValue: number, currentValue: number) =>
    previousValue + currentValue;

  const getAccesoriosValue = accesorios.map(
    (a: any) => results(String(a.valor_unitario)).real * Number(a.cantidad)
  );

  return getAccesoriosValue.reduce(reducer, 0);
};

export const anhoMax = new Date().getFullYear() + 1;

export const subtractMonth = (date: string) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - 1);
  return newDate.toLocaleDateString("en-CA");
};
