import styled from "styled-components";

export const StyledSelect = styled.select`
  border: 0;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  outline: none;
  color: #6c6b6b;
  height: 30px;
  min-width: 155px;
  max-width: 190px;
  width: 155px;

  @media (max-width: 764px) {
    width: 183px;
  }
`;

export const Option = styled.option`
  background-color: #d3d3d3;
  cursor: not-allowed !important;
`;
