// Libraries
import { FC } from "react";

// Styles
import { Children, Wrapper } from "./styles";

//Assets
import Bg from "../../assets/images/background.jpg";

interface Props {
  children: React.ReactNode;
}

export const LoginLayout: FC<Props> = ({ children }) => {
  return (
    <Wrapper style={{ backgroundImage: `url(${Bg})` }}>
      <Children>{children}</Children>
    </Wrapper>
  );
};
