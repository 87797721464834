import { AxiosResponse } from "axios";

import { ICotizar, ISimularResponse } from "../../Interfaces/Cotizar.interface";

import { instance } from "../api";

type SimulacionResponse = AxiosResponse<ISimularResponse>;

export const simulacionCotizar = async (
  body: ICotizar
): Promise<SimulacionResponse> => {
  const { data } = await instance.post("Simulaciones", {
    ...body,
    idmoneda_financiamiento: 2,
  });
  return data;
};
