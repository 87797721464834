import { AxiosResponse } from "axios";

import { instance } from "../api";

import {
  FichaCotizacion,
  SendFicha,
} from "../../Interfaces/FichaCotizacion.interface";

type FichaResponse = AxiosResponse<FichaCotizacion>;

export const generarFichaCotizacion = async (
  idsimulacion: number,
  iduser: number
): Promise<FichaResponse> => {
  const { data } = await instance.get(
    `Simulaciones/generarFichaCotizacion/${idsimulacion}/${iduser}`
  );

  return data;
};

export const enviarFichaCotizacion = async (req: SendFicha): Promise<any> => {
  const { data } = await instance.post(
    `Simulaciones/enviarFichaCotizacion`,
    req
  );
  return data;
};
