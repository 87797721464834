import { FC } from "react";
import { Text } from "../../Atoms/Text/Text";

interface Props {
  bien: { descripcion: string; marca: string; modelo: string; anio: number };
}

export const ToolTipBienes: FC<Props> = ({ bien }) => {
  const { descripcion, marca, modelo, anio } = bien;
  return (
    <>
      <Text margin="0">{descripcion}</Text>
      <Text margin="0">Marca: {marca}</Text>
      <Text margin="0">Modelo: {modelo}</Text>
      <Text margin="0">Año: {anio}</Text>
    </>
  );
};
