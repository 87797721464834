import { FC } from "react";
import Tippy from "@tippyjs/react";

import { AiOutlineHome } from "react-icons/ai";

import { Wrapper } from "./styles";

interface Props {
  size?: string;
}

export const BackButton: FC<Props> = ({ size }) => {
  const handleClick = () => {
    window.location.href = "/";
  };

  return (
    <Tippy content="Inicio">
      <Wrapper onClick={handleClick} size={size}>
        <AiOutlineHome size={size === "sm" ? "1.5rem" : "2rem"} />
      </Wrapper>
    </Tippy>
  );
};
