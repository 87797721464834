//Libraries
import { FC, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const env = window as any;

interface Props {
  setCheck: (value: boolean) => void;
  wasError: boolean;
  setCaptchaKey: (value: string | null) => void;
}

export const ReCatpcha: FC<Props> = ({ setCheck, setCaptchaKey, wasError }) => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const TEST_SITE_KEY =
    env.RECAPTCHA_KEY || "6LeoS0oaAAAAACFs2WznWTbLwlPV_0nkVt7_Ddzl";

  const handleChange = (val: string | null) => {
    if (val === null) setCheck(false);
    setCaptchaKey(val);
    setCheck(true);
  };

  useEffect(() => {
    reCaptchaRef.current?.reset();
  }, [wasError]);

  return (
    <ReCAPTCHA
      theme="light"
      ref={reCaptchaRef}
      sitekey={TEST_SITE_KEY}
      onChange={handleChange}
    />
  );
};
