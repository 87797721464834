import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";

import { subtractMonth } from "../../../helpers/calculate";

import { AiFillCloseCircle, AiOutlineFileExcel } from "react-icons/ai";

import { Icon } from "../../Atoms/Icon/Icon";
import { Text } from "../../Atoms/Text/Text";
import { FieldFiltro } from "../../Molecules/FieldFiltro/FieldFiltro";
import { ModalLayout } from "../../Molecules/ModalLayout/ModalLayout";

import { Bottom, Form } from "./styles";
import { Submit } from "../LoginForm/styles";
import { Body, Close, Header } from "../ModalMenu/styles";

import { generarReporte } from "../../../Providers/Simulaciones/Simulaciones.provider";
import { SET_LOADING } from "../../../App/constants/loading";
import { FC } from "react";

interface Props {
  closeModal: () => void;
}

export const ModalReportar: FC<Props> = ({ closeModal }) => {
  const actualdate = new Date().toLocaleDateString("en-CA");
  const idusuario =
    parseInt(JSON.parse(localStorage.getItem("user") || "{}").idusuario) || -1;
  const dispatch = useDispatch();

  const schema = yup
    .object()
    .shape({
      fechaInicio: yup.string(),
      fechaFin: yup.string(),
    })
    .required();

  const methods = useForm({
    defaultValues: {
      fechaInicio: subtractMonth(actualdate),
      fechaFin: actualdate,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = async (formdata: any) => {
    dispatch({ type: SET_LOADING, payload: true });

    try {
      const { data } = await generarReporte({ idusuario, ...formdata });
      const { archivo, base64 } = data;

      const link = document.createElement("a");
      link.href = `data:application/vnd.ms-excel;base64,${base64}`;
      link.download = archivo;
      link.click();

      link.remove();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se pudo descargar el reporte",
        icon: "error",
        confirmButtonText: "Aceptar",
        iconColor: "#f5365c",
      });
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
      closeModal();
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <Header>
        <Icon padding="0.5rem" icon={<AiOutlineFileExcel size={35} />}></Icon>
        <Text fontWeight="semibold" fontSize="1.1rem">
          Exportar cotizaciones
        </Text>
        <Close onClick={closeModal}>
          <AiFillCloseCircle size={28} color={"#ffd135"} />
        </Close>
      </Header>
      <Body>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} id="formreportar">
            <Text
              margin="0"
              color="#444444"
              fontSize="0.9rem"
              fontWeight="normal"
            >
              Por favor, escoger rango de fechas.
            </Text>
            <FieldFiltro
              type="date"
              id="fechaInicio"
              label="Desde"
              max={actualdate}
            />
            <FieldFiltro
              type="date"
              id="fechaFin"
              label="Hasta"
              min={watch("fechaInicio")}
            />
          </Form>
        </FormProvider>
      </Body>
      <Bottom>
        <Submit
          style={{
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
            marginTop: "0.75rem",
          }}
          form="formreportar"
        >
          Generar Reporte
        </Submit>
      </Bottom>
    </ModalLayout>
  );
};
