import styled from "styled-components";

interface Props {
  isHidden?: boolean;
  margin?: string;
  isSimulacion?: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 0.8rem;

  display: ${({ isHidden }) => isHidden && "none"};
  margin: ${({ margin }) => margin};
`;

export const Title = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.dark.main};
  width: 100%;
`;

export const Content = styled.div<{ padding?: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;

  @media (max-width: 640px) {
    justify-content: center;
  }

  @media (min-width: 995px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @media (min-width: 1050px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  @media (min-width: 1118px) {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }

  @media (min-width: 1180px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  padding: ${({ padding }) => padding + " !important"};
`;
