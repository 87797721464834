import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { MissPasswordLayout } from "../../Layouts/MissPassword";

import { PasswordField } from "../../Components/Molecules/PasswordField/PasswordField";
import { SwalModal } from "../../Components/Molecules/SwalModal/SwalModal";
import { Submit } from "../../Components/Molecules/ModalFiltrar/styles";
import { Text } from "../../Components/Atoms/Text/Text";

import { getTokenTemporal, resetPassword } from "../../Providers/Usuario/Usuario.provider";
import { preLogin } from "../../Providers/Login/Login.provider";

import { EncryptData } from "../../helpers/encrypt";

import { Container, Form } from "./styles";

export const RecoverPassword = () => {
  const [token, setToken] = useState("");
  const search = useSearchParams();

  const schema = Yup.object()
    .shape({
      password: Yup.string()
        .required("Ingresar contraseña")
        .required("Ingresar nueva contraseña")
        .min(6, "La contraseña debe tener minimo 6 caracteres")
        .matches(/[0-9]/, "La contraseña debe tener al menos un numero")
        .matches(/[A-Z]/, "La contraseña debe tener al menos una letra mayúscula")
        .matches(/[a-z]/, "La contraseña debe tener al menos una letra minúscula"),
      confirmpassword: Yup.string()
        .required("Ingresar contraseña")
        .equals([Yup.ref("password")], "Las contraseñas no coinciden"),
    })
    .required();

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    const { data } = await getTokenTemporal();
    setToken(data);
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const handlePasswords = (e: any) => {
    const { name, value } = e.target;

    setValue(name, value, { shouldValidate: true });

    const cp = getValues("confirmpassword") + "";

    setValue("password", getValues("password"), {
      shouldValidate: true,
    });

    if (cp.length > 0) {
      setValue("confirmpassword", cp, {
        shouldValidate: true,
      });
    }
  };

  const handleSubmitForm = async (e: any) => {
    const { password } = e;
    const t = search[0].get("t");
    const m = search[0].get("m");

    if (!t || !m) {
      errorModalMessage();
      return;
    }

    try {
      const { data: tokenPreLog } = await preLogin({
        username: m,
        password: "",
        captchatoken: "",
      });

      if (!tokenPreLog) {
        errorModalMessage();
        return;
      }

      const body = {
        correo: m,
        password: EncryptData(password, tokenPreLog),
        token: t,
      };

      const { status } = await resetPassword(body, token);

      if (status === 200) {
        SwalModal()
          .fire({
            title: "Contraseña actualizada",
            text: "Por favor, inicie sesion con su nueva contraseña",
            icon: "success",
            confirmButtonText: "Regresar al inicio de sesion",
            confirmButtonColor: "#ffd135",
            confirmButtonAriaLabel: "#000000",
          })
          .then(() => {
            window.location.href = "/";
          });
      } else errorModalMessage();
    } catch (error) {
      errorModalMessage();
    }
  };

  const errorModalMessage = () => {
    SwalModal()
      .fire({
        title: "Error",
        text: "Usted no tiene permiso para realizar esta acción.",
        icon: "error",
        confirmButtonText: "Salir",
        confirmButtonColor: "#c70303",
      })
      .then(() => {
        window.location.href = "/";
      });
  };

  return (
    <MissPasswordLayout>
      <Text margin="1.1rem 0 0 0" fontWeight="bold" fontSize="0.95rem">
        Ingresa tu nueva contraseña
      </Text>
      <Container>
        <Text fontSize="0.8rem" align="center" fontWeight="thin">
          Para mayor seguridad, no utilices contraseñas usadas en otros servicios digitales.{" "}
          <strong style={{ fontWeight: "500" }}>Idealmente debe contener números, símbolos, letras mayúsculas y minúsculas.</strong>
        </Text>
      </Container>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <PasswordField id="password" width="260px" placeholder="Ingresar Nueva Contraseña" handleChange={handlePasswords} />
          <PasswordField id="confirmpassword" width="260px" placeholder="Repetir Nueva Contraseña" handleChange={handlePasswords} />

          <Submit style={{ marginTop: "0.8rem" }} disabled={!isValid || isSubmitting}>
            ENVIAR
          </Submit>
        </Form>
      </FormProvider>
    </MissPasswordLayout>
  );
};
