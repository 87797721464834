import { AxiosResponse } from "axios";

import { IMarca } from "../../Interfaces/Marca.interface";

import { instance } from "../api";

type MarcasResponse = AxiosResponse<IMarca[]>;

export const getMarcas = async (): Promise<MarcasResponse> => {
  const { data } = await instance.get("Marcas");
  return data;
};