import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

interface PropsSC {
  isHidden?: boolean;
}

export const SimulacionContainer = styled.div<PropsSC>`
  background-color: #fff;
  box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1.25rem;
  padding-top: 0;

  display: ${({ isHidden }) => isHidden && "none"};
`;
