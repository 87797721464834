import styled from "styled-components";

export const ContainerTable = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
`;

export const Container = styled.div`
  @media (max-width: 1180px) {
    overflow-x: scroll;
  }
`;

export const Table = styled.table`
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  min-width: 100%;
`;

export const Tbody = styled.tbody``;

export const Bottom = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  border: none;
  font-size: 0.8rem;
  border-radius: 2px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.colors.dark.main};
  background-color: #ffffff;
  display: grid;
  place-items: center;

  :hover {
    border-radius: 1rem;
    height: 2rem;
    background-color: ${({ theme }) => theme.colors.primary.main};
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export const Numbers = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 0;
  margin-left: 8px;
  margin-right: 8px;

  span {
    align-self: flex-end;
    color: #959595c8;
  }
`;

interface Props {
  isActive: boolean;
}

export const ButtonNumber = styled.button<Props>`
  border: none;
  padding: 0.42rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-size: 0.8rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  border-radius: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.colors.dark.main};
  background-color: #ffffff;

  ${({ isActive, theme }) =>
    isActive &&
    `
    background-color: ${theme.colors.primary.main};
    
    `}

  :hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

export const ButtonFiltrar = styled.button`
  border: none;
  border-radius: 8px;
  background-color: #eceaea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 1rem;
  cursor: pointer;
  transition: 250ms;
  gap: 0.25rem;
  font-family: "Poppins", sans-serif;

  p {
    margin: 0;
    margin-left: 5px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    p,
    svg {
      color: ${({ theme }) => theme.colors.dark.main};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonReportar = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 8px;
  padding: 0.55rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  cursor: pointer;
  transition: 250ms;
  background-color: #eceaea;
  font-family: "Poppins", sans-serif;
  margin-right: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    svg {
      path {
        color: ${({ theme }) => theme.colors.dark.main};
      }
    }
  }
`;
