import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ModalError } from "../../Organisms/ModalError/ModalError";

interface Props {
  children: JSX.Element;
}

export const PrivateRoute: FC<Props> = ({ children }) => {
  const temporal = localStorage.getItem("temporal");
  const lsUser = localStorage.getItem("user");

  const navigate = useNavigate();

  useEffect(() => {
    if (temporal === null && !lsUser) {
      navigate("/login");
      return;
    }

    if (temporal === null || !lsUser) {
      logOut();
    }
  });

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("temporal");

    ModalError({
      title: "La sesion ha expirado",
      text: "",
      confirmButtonText: "Aceptar",
    }).then(() => {
      navigate("/login");
    });
  };

  return children;
};
