import { useFormContext } from "react-hook-form";
import { Container, StyledButton } from "./styles";

export const SimularBtn = () => {
  const { getValues } = useFormContext();
  const { accesorios, isdisabled } = getValues();

  const isBad =
    accesorios.some(
      (acc: any) => !acc.valor_unitario || !acc.cantidad || !acc.idaccesorio
    ) || isdisabled;

  return (
    <Container>
      <StyledButton disabled={isBad}>Simular</StyledButton>
    </Container>
  );
};
