// Libraries
import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

//Hooks
import { useFormatNumbers } from '../../../Hooks/useFormatNumbers'

// Components
import { AddAccesorio } from '../../Molecules/AddAccesorio/AddAccesorio'
import { Accesorio } from '../Accesorio/Accesorio'

// Styled
import { ValorTotalAccs, Wrapper } from './styles'

//Actions
import { SET_CALCULABLE } from '../../../App/constants/calculable'

//
import { fromXtoUF, TotalAccesorios as totalAccesorios } from '../../../helpers/calculate'

export const Accesorios: FC = () => {
  const { control, setValue, getValues, watch } = useFormContext()
  const { accesorios } = useSelector((state: any) => state.bien)
  const dispatch = useDispatch()
  const { results } = useFormatNumbers()

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'accesorios', // unique name for your Field Array
    keyName: 'id',
  })

  const totalAccs = totalAccesorios(watch('accesorios'))

  const totalAccsFormated = results(String(totalAccs)).format

  const addAccesorio = () => {
    append({ idaccesorio: '', valor_unitario: '0', cantidad: '' })
    dispatch({
      type: SET_CALCULABLE,
      payload: {
        accesorios: fields,
      },
    })
  }

  const removeAccesorio = async (id: string) => {
    const i = fields.findIndex((accesorio: any) => accesorio.id === id)
    remove(i)
    const accesories = watch('accesorios')
    dispatch({
      type: SET_CALCULABLE,
      payload: {
        accesorios: accesories,
      },
    })

    const { cantidad, valorunitarioneto, moneda } = getValues()

    const ta = totalAccesorios(accesories)
    const total = ta + cantidad * results(valorunitarioneto).real

    const total_uf = await fromXtoUF(moneda, total)

    setValue('total_uf', total_uf, { shouldValidate: true })
    dispatch({
      type: SET_CALCULABLE,
      payload: {
        total_pesos: total,
        total_uf,
      },
    })
  }

  return (
    <Wrapper>
      <AddAccesorio addAccesorio={addAccesorio} />
      {fields.map((accesorio, index) => (
        <Accesorio
          fields={fields}
          key={accesorio.id}
          id={index}
          uid={accesorio.id}
          removeAccesorio={removeAccesorio}
          Accesorios={accesorios}
        />
      ))}
      {fields.length > 0 && <ValorTotalAccs>Valor Total Accesorios: ${totalAccsFormated}</ValorTotalAccs>}
    </Wrapper>
  )
}
