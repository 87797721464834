import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RESET_DEFAULT_VALUES } from "../../../App/constants/defaultvalues";

import { Btn } from "./styles";

export const GoCotizar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goCotizar = () => {
    dispatch({ type: RESET_DEFAULT_VALUES });

    navigate("/cotizar");
    window.scrollTo(0, 0);
  };
  return <Btn onClick={goCotizar}>Cotizar</Btn>;
};
