import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  svg {
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 100;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
