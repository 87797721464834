import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-right: 5px;
`;

export const Title = styled.p`
  font-size: 13.5px;
  width: 100%;
  text-align: center;
  align-self: center;
  margin: 0;
  color: #6c6b6b;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
`;

export const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  pointer-events: none;
`;
