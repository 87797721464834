import { FC, ReactNode } from "react";

import { Bg, Wrapper } from "./styles";

interface Props {
  closeModal: () => void;
  children: ReactNode;
}

export const ModalLayout: FC<Props> = ({ closeModal, children }) => {
  return (
    <>
      <Bg onClick={closeModal} />
      <Wrapper>{children}</Wrapper>
    </>
  );
};
