import { Thead } from "./styles";

export const TableHead = () => {
  return (
    <Thead>
      <tr>
        <th>Nº Simulación</th>
        <th>RUT</th>
        <th>Cliente</th>
        <th>Cant.</th>
        <th>Bienes</th>
        <th>Valor Cuota</th>
        <th>Plazo</th>
        <th>Fecha de Creación</th>
        <th>Acciones</th>
      </tr>
    </Thead>
  );
};
