import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

import { NumberInput } from "../../Atoms";

import { Container, Error, Label, Lure, Span, Wrapper } from "../Field/styles";

interface Props {
  label: string;
  id: string;
  info?: { id: number; name: string };
  maxLength?: number;
  valorTotalAcc?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FieldNumber: FC<Props> = ({
  label,
  id,
  info,
  handleChange,
  onBlur,
  valorTotalAcc,
  maxLength,
  onFocus,
}) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const { results } = useFormatNumbers();

  //@ts-ignore
  const hasError =
    //@ts-ignore
    info &&
    //@ts-ignore
    errors["accesorios"] &&
    //@ts-ignore
    errors["accesorios"][info.id] &&
    //@ts-ignore
    errors["accesorios"][info.id][info.name];
  //@ts-ignore
  const error = hasError && errors[id].message;

  return (
    <Wrapper>
      <Container>
        <Label isLogin={false} length={label.length}>
          {label}
        </Label>
        <NumberInput
          id={id}
          onChange={handleChange}
          onBlur={onBlur}
          value={watch(id)}
          width="147px"
          decimalSeparator=","
          thousandSeparator="."
          maxLength={maxLength}
          error={!!error}
          onFocus={onFocus}
        />
        {valorTotalAcc && valorTotalAcc !== "0" && !hasError ? (
          <Span>{`Sub Total: $${results(valorTotalAcc).format}`}</Span>
        ) : null}
      </Container>
      {error ? <Error>{error}</Error> : <Lure />}
      {hasError ? (
        //@ts-ignore
        <Error>{errors["accesorios"][info.id][info.name].message}</Error>
      ) : info ? (
        <Lure />
      ) : null}
    </Wrapper>
  );
};
