import { ChangeEvent, FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { formatCelular } from '../../../helpers/validate'

import { Field } from '../Field/Field'

interface Props {
  width?: string
  labelWidth?: string
}

export const CelularField: FC<Props> = ({ width, labelWidth }) => {
  const { setValue, getValues } = useFormContext()

  useEffect(() => {
    const value = getValues('celular')

    if (value && value.length > 0) {
      const formated = formatCelular(value, getValues('celular'))
      setValue('celular', formated, {
        shouldValidate: true,
      })
    }
  }, [getValues, setValue])

  const changeCelular = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let { value } = e.target

    const formated = formatCelular(value, getValues('celular'))
    setValue('celular', formated, {
      shouldValidate: true,
    })
  }

  return (
    <Field
      label="Teléfono (+56)"
      labelWidth={labelWidth}
      id="celular"
      handleChange={changeCelular}
      width={width}
    />
  )
}
