//Libraries
import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

//Helpers
import { formateaRut } from "../../../helpers/validate";

//Components
import { Field } from "../Field/Field";

export const RutField = () => {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const handleBlur = (e?: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setValue("personajuridica", 0, {
        shouldValidate: true,
      });
      const error = errors["rut"];

      if (e && !error) {
        const { value } = e.target;
        const rutNum = value.split("-")[0].replaceAll(".", "");
        const pNatural = Number(rutNum) < 50000000;

        if (pNatural) {
          setValue("personajuridica", 0, { shouldValidate: true });
        } else {
          setValue("personajuridica", 1, { shouldValidate: true });
        }

        setValue("razonsocial", "", { shouldValidate: true });
      }
    }, 3);
  };

  return (
    <Field
      label="RUT"
      id="rut"
      maxLength={12}
      handleChange={(e: any) => {
        const { value } = e.target;
        setValue("rut", formateaRut(value, getValues("rut")));
        handleBlur(e);
      }}
      onBlur={handleBlur}
    />
  );
};
