import { FC } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Close } from "../../Organisms/ModalMenu/styles";

interface Props {
  handleClick: () => void;
}

export const CloseButton: FC<Props> = ({ handleClick }) => {
  return (
    <Close onClick={handleClick}>
      <AiFillCloseCircle size={28} color={"#F7CF46"} />
    </Close>
  );
};
