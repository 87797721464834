// Libraries
import { BrowserRouter as Router } from "react-router-dom";

// Routes
import { Routes } from "./Routes";

// Styles
import { ThemeProvider } from "styled-components";
import { theme, Root, GlobalStyle } from "./Theme/index";
import "tippy.js/dist/tippy.css";

//Components
import { Loading } from "./Components/Molecules/Loading/Loading";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Root>
          <Routes />
        </Root>
      </ThemeProvider>
      <Loading />
    </Router>
  );
}

export default App;
