import styled from "styled-components";

interface Props {
  size?: string;
}

export const Wrapper = styled.div<Props>`
  cursor: pointer;
  transition: 250ms ease-in-out;

  svg {
    transition: 200ms ease-in-out;
  }

  &:hover {
    box-shadow: ${({ size }) =>
      size === "lg" && "0px 0px 5px 0px rgba(0, 0, 0, 0.75)"};

    svg {
      fill: ${({ size }) => (size === "lg" ? null : "#d7af28")};
    }
  }

  width: ${({ size }) => size === "lg" && "65px"};
  height: ${({ size }) => size === "lg" && "65px"};
  background-color: ${({ size }) => size === "lg" && "#ffd135"};
  border-radius: ${({ size }) => size === "lg" && "50%"};
  display: ${({ size }) => size === "lg" && "grid"};
  place-items: ${({ size }) => size === "lg" && "center"};

  svg {
    width: ${({ size }) => size === "lg" && "32px"};
    height: ${({ size }) => size === "lg" && "32px"};
  }

  @media (max-width: 768px) {
    width: ${({ size }) => size === "lg" && "55px"};
    height: ${({ size }) => size === "lg" && "55px"};
  }
`;
