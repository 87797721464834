import styled from "styled-components";

export const Row = styled.tr`
  :hover {
    background-color: #e4e4e4;
  }
`;

export const RowItem = styled.td`
  border-bottom: 1px solid #e5e5e5;
  padding: 0.5rem;
  font-size: 13px;
  text-align: center;
  min-width: 90px;
  color: #595d6e;

  p {
    margin: 0;
  }

  @media (max-width: 764px) {
    font-size: 12px;
  }
`;

export const DownloadButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  svg {
    transition: 200ms ease-in-out;
  }

  &:hover {
    svg {
      fill: #d7af28;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`;

export const Bienes = styled.p`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
