import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { SET_LOADING } from "../../../App/constants/loading";
import { SET_SIMULAR } from "../../../App/constants/simular";

import { useAppDispatch } from "../../../Hooks/useDispatch";
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

import { ICotizar } from "../../../Interfaces/Cotizar.interface";

import { simulacionCotizar } from "../../../Providers/Cotizar/Cotizar.provider";

import { DatosBien } from "../DatosBien/DatosBien";
import { DatosCliente } from "../DatosCliente/DatosCliente";
import { DatosFinanciamiento } from "../DatosFinanciamiento/DatosFinanciamiento";
import { ModalError } from "../ModalError/ModalError";

export const FormCotizar = () => {
  const { dispatch } = useAppDispatch();
  const { results } = useFormatNumbers();
  const { handleSubmit } = useFormContext();

  const { marcas } = useSelector((state: any) => state.marca);

  const { total_pesos } = useSelector((state: any) => state.calculable);
  const { pie_minimo } = useSelector((state: any) => state.bien);

  const getMarca = (id: number) => {
    return marcas.find((marca: any) => marca.id === id);
  };

  const submitForm = (data: any) => {
    dispatch(SET_SIMULAR, {
      idsimulacion: 0,
    });

    const idusuario =
      parseInt(JSON.parse(localStorage.getItem("user") || "{}").idusuario) ||
      -1;

    const submitSchema: ICotizar = {
      idusuario: idusuario,
      nombre: data.nombre,
      personajuridica: data.personajuridica,
      rut: data.rut,
      correo: data.correo,
      celular: data.celular.split("-").join(""),
      razonsocial: data.personajuridica === 1 ? data.razonsocial : "",
      idbien: data.bien,
      marca: getMarca(data.marca)?.nombre || "",
      modelo: data.modelo,
      anio: data.anho,
      cantidadbien: data.cantidad,
      idmoneda: data.moneda,
      valor_unitario: results(data.valorunitarioneto).real,
      valor_total: total_pesos || data.valor_total,
      glosa: data.glosa || "",
      iddeducible: data.deducibles,
      accesorios: data.accesorios,
      valor_total_uf: data.total_uf,
      pie: parseInt(pie_minimo),
      idplazo: data.plazo,
      primera_cuota: data.primeracuotatiempo,
    };

    if (submitSchema.accesorios && submitSchema.accesorios.length > 0)
      submitSchema.accesorios.forEach(
        (a: any) => (a.valor_unitario = results(String(a.valor_unitario)).real)
      );

    simular(submitSchema);
  };

  const simular = async (body: ICotizar) => {
    dispatch(SET_LOADING, true);

    try {
      const { data } = await simulacionCotizar(body);
      dispatch(SET_SIMULAR, data);
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    } catch (error: any) {
      const { message } = error.response.data;
      ModalError({
        title: "Error",
        text: message,
        confirmButtonText: "Aceptar",
      });
    }

    dispatch(SET_LOADING, false);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <DatosCliente />

      <DatosBien />

      <DatosFinanciamiento />
    </form>
  );
};
