import styled from "styled-components";

export const Wrappper = styled.footer`
  background-color: ${({ theme }) => theme.colors.dark.main};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 15px;
  width: 100%;

  @media (max-width: 500px) {
    img {
      height: 46px;
    }

    div {
      p {
        font-size: 13px;
      }
    }
  }

  span {
    height: 35px;
  }
`;

export const VerticalLine = styled.span`
  border-left: 3px solid ${({ theme }) => theme.colors.primary.main};
  height: 40px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;
`;

export const Version = styled.p`
  font-size: 14px;
  color: #c9c9c94b;
  position: absolute;
  right: 0;
  bottom: 100;
  margin-right: 10px;
  margin-top: 70px;
`;
