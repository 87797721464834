import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
  }
`;
