import { Calculable } from "../../Interfaces/Calculable.interface";

import { RESET_CALCULABLE, SET_CALCULABLE } from "../constants/calculable";

interface Action {
  type: string;
  payload: Calculable;
}
const initialState: Calculable = {
  cantidad: 0,
  valorunitarioneto: 0,
  moneda: 1,
  total_pesos: 0,
  total_uf: 0,
  accesorios: [],
};

export function calculableReducer(
  state: Calculable = initialState,
  action: Action
) {
  switch (action.type) {
    case SET_CALCULABLE:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_CALCULABLE:
      return {
        cantidad: 0,
        valorunitarioneto: 0,
        moneda: 1,
        total_pesos: 0,
        total_uf: 0,
        accesorios: [],
      };
    default:
      return state;
  }
}
