import { AxiosResponse } from "axios";

import { IUser } from "../../App/reducers/userReducer";

import { ILogin } from "../../Interfaces/Login.interface";

import { instance } from "../api";

type LoginResponse = AxiosResponse<IUser>;

export const preLogin = async (body: ILogin): Promise<AxiosResponse> => {
  const { data } = await instance.post("Login/preLogin", body);
  return data;
};

export const Login = async (body: ILogin): Promise<LoginResponse> => {
  const { data } = await instance.post("Login", body);
  return data;
};
