//Interfaces
import { ISimularResponse } from "../../Interfaces/Cotizar.interface";

//Redux Action
import { SET_SIMULAR } from "../constants/simular";

interface Action {
  type: string;
  payload: ISimularResponse;
}

const initialState: ISimularResponse = {
  idsimulacion: 0,
  nro_plazos: 0,
  valor_plazo_uf: 0,
  valor_total: 0,
  valor_total_uf: 0,
  monto_financiar_uf: 0,
};

export function simularReducer(
  state: ISimularResponse = initialState,
  action: Action
) {
  switch (action.type) {
    case SET_SIMULAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
