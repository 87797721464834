import { FC } from "react";

interface Props {
  src: string;
  alt: string;
  height?: string;
}

export const Image: FC<Props> = ({ src, alt, height }) => {
  return <img src={src} alt={alt} height={height} />;
};
