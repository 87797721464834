import styled from "styled-components";

export const Bg = styled.div`
  position: fixed;
  z-index: 9;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 10px;
  max-width: 100%;

  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
`;
