import styled from 'styled-components'

export const Wrapper = styled.input`
  border: none;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  outline: none;
  color: #6c6b6b;
  padding-left: 8px;
  height: 30px;
  box-sizing: border-box;

  width: 155px;

  @media (max-width: 764px) {
    width: 183px;
  }
`
