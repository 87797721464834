import { FC, ReactNode } from "react";
import { StyledText } from "./styles";

type Weigth = "normal" | "semibold" | "bold" | "thin";

interface Props {
  children: ReactNode;
  fontSize?: string;
  fontWeight?: Weigth;
  color?: string;
  margin?: string;
  align?: "left" | "center" | "right";
  onClick?: () => void;
  pointer?: boolean;
}

export const Text: FC<Props> = ({
  children,
  color,
  fontSize,
  fontWeight = "normal",
  margin,
  pointer,
  align,
  onClick,
}) => {
  const weight =
    fontWeight === "bold"
      ? "700"
      : fontWeight === "semibold"
      ? "500"
      : fontWeight === "normal"
      ? "400"
      : "200";
  const textStyle = {
    color,
    fontSize,
    fontWeight: weight,
    margin,
    textAlign: align,
    cursor: pointer ? "pointer" : "default",
  };

  return (
    <StyledText style={textStyle} onClick={onClick}>
      {children}
    </StyledText>
  );
};
