//Libraries
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Components
import { Simulacion } from "../../Components/Organisms/Simulacion/Simulacion";
import { FormCotizar } from "../../Components/Organisms/FormCotizar/FormCotizar";

//Layouts
import { MainLayout } from "../../Layouts/Main";

//Styled Components
import { SimulacionContainer, Wrapper } from "./styles";

//Redux Actions

//Helpers
//import { validaRut } from '../../helpers/validate'
import { anhoMax } from "../../helpers/calculate";

//Interfaces
import { useFormatNumbers } from "../../Hooks/useFormatNumbers";
import { validaRut } from "../../helpers/validate";

export const Cotizar = () => {
  const { results } = useFormatNumbers();

  const defaultValues = useSelector((state: any) => state.default);
  const { idsimulacion } = useSelector((state: any) => state.simulacion);
  const { pie_minimo_min, pie_maximo } = useSelector(
    (state: any) => state.bien
  );

  const schema = yup
    .object()
    .shape({
      nombre: yup.string().required("Ingresar nombre"),
      rut: yup
        .string()
        .required("Ingresar RUT")
        .test({
          test: (rut) => {
            if (validaRut(rut)) return true;
            else return false;
          },
          message: "Rut inválido",
        }),
      correo: yup
        .string()
        .required("Ingresar correo")
        .email("El correo no es válido")
        .test({
          test: (val) => {
            if (val) {
              const valids = /^[a-zA-Z@.-_]+$/;
              const no_valid_symbols =
                val.includes("--") || val.includes("__") || val.includes("..");

              if (val.match(valids) && !no_valid_symbols) return true;
              return false;
            }
            return false;
          },
          message: "Caracteres no válidos",
        }),
      razonsocial: yup.string(),
      celular: yup
        .string()
        .required("Ingresar Celular")
        .test({
          test: (celular) => {
            if (celular?.length === 11) return true;
            else return false;
          },
          message: "El celular debe tener 9 dígitos",
        }),
      bien: yup
        .number()
        .required("Escoger Bien")
        .typeError("Escoger Bien")
        .min(1, "Escoger Bien"),
      marca: yup
        .number()
        .required("Escoger marca")
        .typeError("Escoger marca")
        .min(1, "Escoger marca"),
      modelo: yup.string().required("Ingresar modelo"),
      anho: yup
        .number()
        .required("Ingresar año")
        .min(2000, "Año minimo: 2000")
        .max(anhoMax, "Año maximo: " + anhoMax)
        .typeError("Año no válido"),
      cantidad: yup
        .number()
        .required("Ingresar cantidad")
        .min(1, "Minimo 1")
        .max(100, "Maximo 100")
        .typeError("Cantidad no válida"),
      moneda: yup
        .number()
        .required("Escoger Moneda")
        .typeError("Escoger Moneda"),
      deducibles: yup
        .number()
        .required("Escoger deducible")
        .typeError("Escoger deducible")
        .test({
          test: (deducibles: any) => {
            if (deducibles > -1) return true;
            return false;
          },
          message: "Escoger deducible",
        }),
      valorunitarioneto: yup
        .string()
        .required("Ingresar valor unitario neto")
        .min(1, "Valor minimo: 1")
        .test({
          test: (valorunitarioneto: any) => {
            const { real } = results(valorunitarioneto);
            if (real > 0) return true;
            return false;
          },
          message: "Minimo 1",
        })
        .typeError("Valor no válido"),
      pie: yup
        .number()
        .required("Ingresar pago inicial")
        .min(pie_minimo_min, "Minimo " + pie_minimo_min)
        .max(pie_maximo, "Maximo " + pie_maximo)
        .typeError("Pago no válido"),
      plazo: yup
        .number()
        .required("Ingresar plazo")
        .typeError("Plazo no válido"),
      accesorios: yup.array().of(
        yup.object().shape({
          idaccesorio: yup
            .number()
            .required("Escoger accesorio")
            .typeError("Escoger accesorio")
            .min(1, "Escoger accesorio"),
          valor_unitario: yup
            .string()
            .required("Ingresar valor")
            .min(1, "Valor minimo: 1")
            .test({
              test: (valor_unitario: any) => {
                const { real } = results(valor_unitario);
                if (real > 0) return true;
                return false;
              },
              message: "Minimo 1",
            }),
          cantidad: yup
            .number()
            .required("Ingresar cantidad")
            .typeError("Ingresar cantidad"),
        })
      ),
    })
    .required();

  if (defaultValues.accesorios && defaultValues.accesorios.length > 0)
    defaultValues.accesorios.forEach((a: any) => {
      a.valor_unitario = results(String(a.valor_unitario)).format;
      a.cantidad = String(a.cantidad);
      delete a.id;
    });

  const methods = useForm({
    defaultValues: {
      nombre: defaultValues.nombre,
      rut: defaultValues.rut,
      correo: defaultValues.correo,
      celular: defaultValues.celular,
      razonsocial: defaultValues.razonsocial,
      deducibles: defaultValues.iddeducible || -1,
      personajuridica: defaultValues.personajuridica || 0,
      primeracuotatiempo: defaultValues.primera_cuota || 60,
      marca: defaultValues.idmarca || -1,
      bien: defaultValues.idbien || -1,
      modelo: defaultValues.modelo,
      moneda: defaultValues.idmoneda || 1,
      anho: defaultValues.anio || "",
      plazo: defaultValues.idplazo || 0,
      glosa: defaultValues.glosa,
      cantidad: defaultValues.cantidadbien,
      valorunitarioneto:
        results(String(defaultValues.valor_unitario)).format || "",
      valortotal: defaultValues.cantidadbien * defaultValues.valor_unitario,
      valor_total: defaultValues.valor_total,
      total_uf: defaultValues.valor_total_uf,
      accesorios: defaultValues.accesorios || [],
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    mode: "all",
  });
  const {
    formState: { errors },
    setValue,
  } = methods;
  const { deducibles, bien, marca } = errors;

  useEffect(() => {
    setValue("total_uf", defaultValues.valor_total_uf);
    if (bien || marca) window.scrollTo(0, 285);
    if (deducibles) window.scrollTo(0, 275);
  }, [defaultValues.valor_total_uf, setValue, deducibles, bien, marca]);

  return (
    <MainLayout>
      <Wrapper>
        <FormProvider {...methods}>
          <FormCotizar />
        </FormProvider>
      </Wrapper>
      <SimulacionContainer isHidden={idsimulacion === 0}>
        <Simulacion />
      </SimulacionContainer>
    </MainLayout>
  );
};
