import { useSelector } from "react-redux";

//Components
import { TableCotizaciones } from "../../Components/Organisms/TableCotizaciones/TableCotizaciones";
import { Section } from "../../Components/Organisms/Section/Section";
import { GoCotizar } from "../../Components/Atoms/GoCotizar/GoCotizar";

//
import { MainLayout } from "../../Layouts/Main";

//Styles
import { Header, Leyenda, Wrapper } from "./styles";

//Assets
import Logo from "../../assets/svg/big-logo.svg";

export const Home = () => {
  const { simulaciones } = useSelector((state: any) => state.combo);

  return (
    <MainLayout>
      <Wrapper>
        <Header>
          <img src={Logo} alt="logo progreso" width={152} />
          <Section title="COTIZACIONES" padding="0 0 0 1rem">
            <Leyenda>
              Acá podrás revisar en detalle la información relevante vinculada a
              tus cotizaciones de Leasing Progreso.
            </Leyenda>
          </Section>
        </Header>
        <GoCotizar />
        {simulaciones && <TableCotizaciones grilla={simulaciones} />}
      </Wrapper>
    </MainLayout>
  );
};
