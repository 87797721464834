//Libraries
import { FC } from "react";
import { useSelector } from "react-redux";
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

//Styled Components
import { Number, Text, Title, Wrapper, Content, UF } from "./styles";

interface Props {
  plazo?: boolean;
}

export const Cart: FC<Props> = ({ plazo }) => {
  const { nro_plazos, valor_plazo_uf } = useSelector(
    (state: any) => state.simulacion
  );
  const { results } = useFormatNumbers();

  return (
    <Wrapper>
      <Title>{plazo ? "Plazo" : "Cuota Mensual"}</Title>
      <Content>
        {!plazo && <UF>UF&nbsp;</UF>}
        <Number>
          {plazo ? nro_plazos : results(String(valor_plazo_uf)).format}
        </Number>
        {plazo && <Text style={{ paddingLeft: "0.5rem" }}>meses</Text>}
      </Content>
      {plazo ? (
        <Text style={{ fontSize: "1rem" }}>+ Opción</Text>
      ) : (
        <Text style={{ fontSize: "1rem" }}>+ IVA</Text>
      )}
    </Wrapper>
  );
};
