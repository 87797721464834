import { IBien } from '../../Interfaces/Bien.interface'
import { RESET_BIEN, SET_BIEN } from '../constants/bien'

interface Action {
  type: string
  payload: IBien
}
const initialState = {
  idbien: 0,
  nombre: '',
  pie_minimo: 0,
  pie_minimo_min: 0,
  idcategoria: 0,
  idramo: 0,
  vida_normal: 0,
  vida_acelerada: 0,
  idrubro: 0,
  idrubroespecifico: 0,
  deducible: [
    {
      id: 0,
      nombre: '',
    },
  ],
  accesorios: [],
}

export function bienReducer(state = initialState, action: Action) {
  switch (action.type) {
    case SET_BIEN:
      action.payload.accesorios.unshift({ id: -1, nombre: '-- Seleccionar --' })
      return {
        ...state,
        ...action.payload,
      }
    case RESET_BIEN:
      return initialState
    default:
      return state
  }
}
