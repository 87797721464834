import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { RESET_DEFAULT_VALUES } from '../../../App/constants/defaultvalues'
import { Btn } from '../GoCotizar/styles'

interface Props {
  to: string
  text: string
}

export const GoButton: FC<Props> = ({ to, text }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goCotizar = () => {
    dispatch({ type: RESET_DEFAULT_VALUES })

    navigate(to)
    window.scrollTo(0, 0)
  }
  return <Btn onClick={goCotizar}>{text}</Btn>
}
