import styled from "styled-components";

export const Bg = styled.div`
  position: fixed;
  z-index: 9;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 10px;
  max-width: 100%;
  padding-bottom: 0.8rem;

  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 0.5rem;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
  color: #000000;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: #828282;
`;

export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  ::after {
    content: "";
    height: 16px;
    width: 16px;
    background-color: #000000;
    position: absolute;
    z-index: -1;
  }

  svg {
    path {
      height: 24.5px;
      width: 24.5px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface SectionProps {
  border?: boolean;
  center?: boolean;
}

export const Section = styled.div<SectionProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 1.8fr;
  place-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;

  ${({ border }) => border && "border-bottom: 1px solid #e1e1e1;"}
  ${({ center }) =>
    center && {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem",
    }}
`;

export const SectionContent = styled.div`
  width: 100%;
`;

export const FullName = styled.h4`
  margin-bottom: 0;
  margin-left: 0;
  color: #2c2c2c;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  padding-left: 20px;
`;

export const ListItem = styled.li`
  color: #828282;
  font-size: 14px;
  font-size: 0.8rem;
`;

interface ButtonProps {
  width?: string;
}

export const Button = styled.button<ButtonProps>`
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.dark.main};
  padding: 0.6rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;

  ${({ width }) => width && `width: ${width}`}

  @media (min-width: 768px) {
    width: 180px;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding-right: 0.8rem;
`;
