import styled from 'styled-components'

export const Btn = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.dark.main};
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  transition: 100ms ease-in-out;
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;

  :hover {
    background-color: #ffc107;
    color: #000000;
  }
`
