import { ChangeEvent, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Wrapper } from "./styles";

interface Props {
  id: string;
}

export const PagoInicial: FC<Props> = ({ id }) => {
  const bien = useSelector((state: any) => state.bien);
  const dispatch = useDispatch();
  const { register } = useFormContext();

  const { pie_minimo } = bien;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(",", ".");

    dispatch({
      type: "SET_BIEN",
      payload: {
        ...bien,
        pie_minimo: e.target.value,
      },
    });
  };

  return (
    <>
      <Wrapper
        {...register(id)}
        value={pie_minimo}
        max={100}
        onChange={handleChange}
        maxLength={4}
      />
      %
    </>
  );
};
