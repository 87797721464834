//Libraries
import { FC, ReactNode } from "react";

//Components
import { Footer } from "../../Components/Organisms/Footer/Footer";
import { Header } from "../../Components/Organisms/Header/Header";

//Styles
import { Content, Wrapper } from "./styles";

//Props
interface Props {
  children: ReactNode;
}

export const MainLayout: FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
};
