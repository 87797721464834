import { Action } from "redux";
import { SET_FILTRO } from "../constants/filtro";

interface IAction extends Action {
  payload: any;
}

interface IState {
  bienSelected: string;
}

const initialState = {
  bienSelected: "",
};

export function filtroReducer(state: IState = initialState, action: IAction) {
  switch (action.type) {
    case SET_FILTRO:
      return {
        ...state,
        bienSelected: action.payload,
      };
    default:
      return state;
  }
}
