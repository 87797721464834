import { AxiosResponse } from 'axios'

import { Simulacion } from '../../Interfaces/Simulacion.interface'

import { instance } from '../api'

type Response = AxiosResponse<{ grilla: Simulacion[] }>

interface Props {
  idsimulacion?: number
  fechaInicio?: string
  fechaFin?: string
  rut?: string
  cliente?: string
  bien?: string
}

export const getSimulacion = async (idusuario: number, props?: Props): Promise<Response> => {
  const { data } = await instance.get(`Simulaciones/bandeja/${idusuario}`, {
    params: {
      idsimulacion: props?.idsimulacion,
      fechaInicio: props?.fechaInicio,
      fechaFin: props?.fechaFin,
      rut: props?.rut,
      cliente: props?.cliente,
      bien: props?.bien,
    },
  })
  return data
}

export const deleteSimulacion = async (body: { idsimulacion: number; idusuario: number }) => {
  const { data } = await instance.put(`Simulaciones/eliminar`, body)
  return data
}

export const getSimulacionById = async (body: { idsimulacion: number; idusuario: number }) => {
  const { data } = await instance.get(`Simulaciones/${body.idusuario}/${body.idsimulacion}`)
  return data
}

export const generarReporte = async (body: { idusuario: number; fechaInicio: string; fechaFin: string }) => {
  const { idusuario, fechaInicio, fechaFin } = body
  const { data } = await instance.get(`Simulaciones/generarReporte/${idusuario}/${fechaInicio}/${fechaFin}`)
  return data
}
