//Libraries
import { useFormContext } from "react-hook-form";
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

//Styles
import { Wrapper } from "./styles";

export const ValorTotal = () => {
  const { watch } = useFormContext();
  const { results } = useFormatNumbers();
  const { total_uf } = watch();
  const val = results(String(total_uf.toFixed(2))).format;
  return <Wrapper value={val} readOnly type="text" />;
};
