import styled from "styled-components";

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.dark.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
`;
