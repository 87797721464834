import { AxiosResponse } from "axios";

import { instance } from "../api";

type PublicKeyResponse = AxiosResponse<{ publicKey: string } | any>;

export const getPublicKey = async (
  idusuario: number
): Promise<PublicKeyResponse> => {
  const { data } = await instance.get(`Usuarios/getKey/${idusuario}`);
  return data;
};
