//Libraries
import { FC, ReactNode } from "react";

// Components
import { Image } from "../../Components/Atoms/Image/Image";

// Styles
import { Body, Content, HeaderContent, Title, Wrapper } from "./styles";
import { Line } from "../Login/styles";

//Assets
import Logo from "../../assets/images/custom-logo.png";
import Bg from "../../assets/images/background.jpg";

interface Props {
  children: ReactNode;
}

export const MissPasswordLayout: FC<Props> = ({ children }) => {
  return (
    <Wrapper style={{ backgroundImage: `url(${Bg})` }}>
      <Content>
        <HeaderContent>
          <Image src={Logo} alt="Logo" height="34.5px" />
          <Line style={{ width: "30px", marginTop: 0 }} />
          <Title>Cambio de contraseña</Title>
        </HeaderContent>
        <Body>{children}</Body>
      </Content>
    </Wrapper>
  );
};
