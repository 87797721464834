//Libraries
import Tippy from '@tippyjs/react'
import { FC } from 'react'

//Styles
import { Option, Wrapper } from './styles'

interface Props {
  prompt: string
  isOpen: boolean
  options: Object[]
  setIsOpen: (isOpen: boolean) => void
  handleChange?: (opt: any) => void
}

export const Options: FC<Props> = ({ prompt, isOpen, options, setIsOpen, handleChange }) => {
  return (
    <Wrapper isOpen={isOpen}>
      {options.map((option: any, index) => {
        const isSelected = option.nombre === prompt
        const hasDisponibleProperty = option.hasOwnProperty('disponible')
        const isDisabled = hasDisponibleProperty && !option.disponible
        const isDifferent = option.nombre !== prompt

        return (
          <Tippy content="No disponible" delay={isDisabled ? 200 : 99999} key={index}>
            <Option
              isSelected={isSelected}
              isDisabled={isDisabled}
              onClick={() => {
                if (!isDisabled && isDifferent) {
                  if (handleChange) handleChange(option)
                  setIsOpen(false)
                }
              }}
            >
              {option.nombre}
            </Option>
          </Tippy>
        )
      })}
    </Wrapper>
  )
}
