import styled from "styled-components";

export const Wrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
  color: #363530;
  font-weight: 400;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  gap: 1.5rem;

  @media (max-height: 700px) {
    overflow-y: scroll;
    max-height: 420px;
  }
`;

export const Submit = styled.button`
  width: 200px;
  height: 40px;
  border: none;
  background-color: #ffd135;
  border-radius: 7px;
  margin-top: 2rem;
  cursor: pointer;
  transition: 200ms;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 29.5px;
  right: 20px;
  cursor: pointer;

  :hover {
    svg {
      color: gray;
    }
  }
`;
