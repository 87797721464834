import { FC } from "react";
import { AiOutlineForm } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";

import { SET_DEFAULT_VALUES } from "../../../App/constants/defaultvalues";

import { getSimulacionById } from "../../../Providers/Simulaciones/Simulaciones.provider";

import { useLoader } from "../../../Hooks/useLoader";
import { useAppDispatch } from "../../../Hooks/useDispatch";

import { ModalError } from "../../Organisms/ModalError/ModalError";

import { Wrapper } from "./styles";

interface Props {
  idsimulacion: number;
  idusuario: number;
}

export const EditCotizacion: FC<Props> = ({ idsimulacion, idusuario }) => {
  const navigate = useNavigate();
  const { dispatch } = useAppDispatch();
  const { setLoadingTrue, setLoadingFalse } = useLoader();

  const handleClick = async () => {
    setLoadingTrue();
    try {
      const simulacion = await fetchData();
      dispatch(SET_DEFAULT_VALUES, simulacion);
    } catch (error) {
      ModalError({
        text: "No se pudo cargar la cotización",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoadingFalse();
    }

    navigate("/cotizar");
  };

  const fetchData = async () => {
    const { data } = await getSimulacionById({ idsimulacion, idusuario });
    return data;
  };

  return (
    <Tippy content="Editar">
      <Wrapper onClick={handleClick}>
        <AiOutlineForm size={23} color="#000000" />
      </Wrapper>
    </Tippy>
  );
};
