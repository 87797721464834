import NumberFormat from 'react-number-format'
import styled from 'styled-components'

interface Props {
  error?: boolean
  width?: string
  isLogin?: boolean
}

export const NumberInput = styled(NumberFormat)<Props>`
  border: none;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  outline: none;
  color: #6c6b6b;
  padding: 0px;
  padding-left: 8px;
  height: 30px;
  width: ${(props) => props.width};
  transition: 150ms ease-in-out;
  font-family: 'Poppins', sans-serif;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  ::placeholder {
    color: #6c6b6b;
  }

  :focus {
    box-shadow: 0px 0px 2px 1.4px ${({ theme }) => theme.colors.primary.main};
  }

  box-shadow: ${({ error }) => error && '0px 0px 2px 0.4px red !important'};

  @media (max-width: 764px) {
    width: 175px;
  }

  width: ${({ isLogin }) => isLogin && '250px !important'};

  :disabled {
    background-color: #eceaeacc;
  }
`

export const InputStyle = styled.input<Props>`
  border: none;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  outline: none;
  color: #6c6b6b;
  padding: 0px;
  padding-left: 8px;
  height: 30px;
  width: ${(props) => props.width};
  transition: 150ms ease-in-out;
  font-family: 'Poppins', sans-serif;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  ::placeholder {
    color: #6c6b6b;
  }

  :focus {
    box-shadow: 0px 0px 2px 1.4px ${({ theme }) => theme.colors.primary.main};
  }

  box-shadow: ${({ error }) => error && '0px 0px 2px 0.4px red !important'};

  @media (max-width: 764px) {
    width: 175px;
  }

  width: ${({ isLogin }) => isLogin && '250px !important'};

  :disabled {
    background-color: #eceaeacc;
  }
`
