import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  justify-content: center;
  align-items: center;

  div > img {
    height: 48px;
  }

  @media (max-width: 768px) {
    div > img {
      height: 24px;
    }

    p {
      font-size: 0.7rem;
    }
  }
`;

export const IconContainer = styled.div`
  background-color: #ffd135;
  border-radius: 50%;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
`;
