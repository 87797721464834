//Libraries
import { ChangeEvent, FC } from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

//Components
import { Image } from '../../Atoms/Image/Image'
import { Field } from '../../Molecules/Field/Field'

//Styled Components
import { AlignCenter, Wrapper } from './styles'

//Assets
import TrashIcon from '../../../assets/svg/trash-icon.svg'

//Constants
import { SET_CALCULABLE } from '../../../App/constants/calculable'

//Helpers
import { fromXtoUF, TotalAccesorios } from '../../../helpers/calculate'
import { useFormatNumbers } from '../../../Hooks/useFormatNumbers'
import { FieldNumber } from '../../Molecules/FieldNumber'

interface Props {
  removeAccesorio: (id: string) => void
  id: any
  uid: string
  fields: any
  Accesorios: any[]
}

export const Accesorio: FC<Props> = ({
  removeAccesorio,
  fields,
  id,
  Accesorios,
  uid,
}) => {
  const { getValues, setValue, watch } = useFormContext()
  const dispatch = useDispatch()
  const { results } = useFormatNumbers()
  const preid = `accesorios[${id}]`
  const { valorunitarioneto, moneda, accesorios } = watch()

  accesorios[id].id = uid

  const cantidad = accesorios[id].cantidad
  const valorunitario = accesorios[id].valor_unitario
  const { real } = results(String(results(String(valorunitario)).real))
  const total = results((cantidad * Number(real)).toString()).format

  const newAccs = () => {
    const newaccs = accesorios
    newaccs.forEach(
      (field: any) =>
        (field.valor_unitario = results(String(field.valor_unitario)).real)
    )
    return newaccs
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const ta = TotalAccesorios(newAccs())
    const { id } = e.target
    const name = id.split('.')[1]
    const index = id.split('.')[0].split('[')[1].split(']')[0]

    let val = e.target.value

    if (isNaN(results(val).real)) {
      if (name === 'valor_unitario') accesorios[index]['valor_unitario'] = 0
      if (name === 'cantidad') accesorios[index]['cantidad'] = 0
      setValue(`accesorios[${index}][${name}]`, accesorios[index][name], {
        shouldValidate: true,
      })

      const total = valorunitario * watch('cantidad') + ta
      setValue('valor_total', total, { shouldValidate: true })
      return
    }

    if (name === 'valor_unitario') {
      setValue(`accesorios[${index}][${name}]`, results(val).format, {
        shouldValidate: true,
      })
    } else
      setValue(`accesorios[${index}][${name}]`, val, {
        shouldValidate: true,
      })

    const total = valorunitario * watch('cantidad') + ta

    setValue('valor_total', total, { shouldValidate: true })

    dispatch({
      type: SET_CALCULABLE,
      payload: {
        accesorios,
      },
    })
  }

  const handleBlur = async (e: any) => {
    const ta = TotalAccesorios(newAccs())

    const { cantidad } = getValues()

    const total = ta + cantidad * results(String(valorunitarioneto)).real
    setValue('valor_total', total, { shouldValidate: true })

    const total_uf = await fromXtoUF(moneda, total)

    setValue('total_uf', total_uf, { shouldValidate: true })

    dispatch({
      type: SET_CALCULABLE,
      payload: {
        total_pesos: total,
        total_uf,
      },
    })
  }

  const handleChangeSelect = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { id } = e.target
    const name = id.split('.')[1]
    const index = id.split('.')[0].split('[')[1].split(']')[0]

    setValue(`accesorios[${index}][${name}]`, e.target.value, {
      shouldValidate: true,
    })
  }

  return (
    <Wrapper>
      <Field
        label="Accesorio"
        select={Accesorios}
        id={`${preid}.idaccesorio`}
        info={{ id, name: 'idaccesorio' }}
        handleChange={handleChangeSelect}
      />

      <Field
        label="Cantidad"
        id={`${preid}.cantidad`}
        type="number"
        handleChange={handleChange}
        onBlur={handleBlur}
        info={{
          id,
          name: 'cantidad',
        }}
      />
      <AlignCenter>
        <FieldNumber
          label="Valor U. Neto"
          id={`${preid}.valor_unitario`}
          handleChange={handleChange}
          onBlur={handleBlur}
          valorTotalAcc={total}
          info={{
            id,
            name: 'valor_unitario',
          }}
        />
        <div
          onClick={() => {
            removeAccesorio(uid)
          }}
          className="trash-icon"
        >
          <Image src={TrashIcon} alt="Trash Icon" />
        </div>
      </AlignCenter>
    </Wrapper>
  )
}
