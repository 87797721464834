import {
  RESET_DEFAULT_VALUES,
  SET_DEFAULT_VALUES,
} from "../constants/defaultvalues";

type DefaultValuesActions = {
  type: string;
  payload: any;
};

type DefaultValuesState = {
  nombre: string;
  rut: string;
  personajuridica: number;
  razonsocial: string;
  correo: string;
  celular: string;
  idbien: number;
  idmoneda: number;
  iddeducible: number;
  idplazo: number;
  idmoneda_financiamiento: number;
  idmarca: number;
  modelo: string;
  pie: number;
  primera_cuota: number;
  valor_total: number;
  valor_total_uf: number;
  valor_unitario: number;
  anio: number;
  cantidadbien: number;
  glosa: string;
  accessorios: [];
};

const initialState: DefaultValuesState = {
  nombre: "",
  rut: "",
  personajuridica: 0,
  razonsocial: "",
  correo: "",
  celular: "",
  idbien: -1, 
  idmoneda: 0,
  accessorios: [],
  iddeducible: 0,
  idplazo: 0,
  idmoneda_financiamiento: 0,
  idmarca: -1,
  modelo: "",
  pie: 0,
  primera_cuota: 0,
  valor_total: 0,
  valor_total_uf: 0,
  valor_unitario: 0,
  anio: 0,
  cantidadbien: 0,
  glosa: "",
};

export function defaultValuesReducer(
  state: DefaultValuesState = initialState,
  action: DefaultValuesActions
): DefaultValuesState {
  switch (action.type) {
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DEFAULT_VALUES:
      return initialState;
    default:
      return state;
  }
}
