import axios from "axios";

const environment = process.env.REACT_APP_ENV;
const env = window as any;

const baseUrl =
  environment === "production" || environment === "qa"
    ? process.env.REACT_APP_API_URL
    : env.API_URL || "http://18.220.215.25:9220/api/v1";
// : "http://Acá iria la url de prod";

export const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (user && user.token) {
    return {
      Authorization: `Bearer ${user.token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
};

export const instance = axios.create({
  baseURL: baseUrl,
  headers: authHeader(),
  responseType: "json",
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      localStorage.removeItem("temporal");
      window.location.href = "/";
      return;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("temporal");
        window.location.href = "/";
        return;
      }
    }

    return Promise.reject(error);
  }
);
