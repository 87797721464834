import styled from "styled-components";

interface Props {
  width?: string;
}

export const Wrapper = styled.div<Props>`
  height: 30px;
  width: 155px;
  border: none;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  cursor: default;
  z-index: 1;

  ${({ width }) => width && `width: ${width} !important;`}

  @media (max-width: 764px) {
    width: 183px !important;
  }
`;
