//Libaries
import { FC } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";

//Providers
import { generarFichaCotizacion } from "../../../Providers/FichaCotizacion/FichaCotizacion.provider";

//Styles
import { Link, Wrapper } from "./styles";
import { DownloadButton } from "../CotizacionRow/styles";

//Assets
import Icon from "../../../assets/svg/download-icon.svg";
import { AiOutlineCloudDownload } from "react-icons/ai";

//Actions
import { useLoader } from "../../../Hooks/useLoader";
import { ModalError } from "../../Organisms/ModalError/ModalError";

interface Props {
  size: "sm" | "lg";
  id?: number;
}

export const Download: FC<Props> = ({ size, id }) => {
  const { idsimulacion } = useSelector((state: any) => state.simulacion);
  const { setLoadingTrue, setLoadingFalse } = useLoader();

  const fetchFicha = async () => {
    const user = localStorage.getItem("user");
    const iduser = JSON.parse(user || "").idusuario;

    const { data } = await generarFichaCotizacion(
      size === "sm" ? id : idsimulacion,
      iduser
    );
    return data;
  };

  const downloadClick = async () => {
    setLoadingTrue();

    try {
      const { archivo, base64 } = await fetchFicha();
      await new Promise((resolve) => setTimeout(resolve, 100));

      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${base64}`;
      link.download = archivo;

      link.click();
      link.remove();
    } catch (error) {
      ModalError({
        text: "No se pudo descargar la ficha",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoadingFalse();
    }
  };

  return (
    <Tippy content="Descargar PDF">
      {size === "lg" ? (
        <Wrapper>
          <Link onClick={downloadClick}>
            <img src={Icon} alt="Download icon" />
          </Link>
        </Wrapper>
      ) : (
        <DownloadButton onClick={downloadClick}>
          <AiOutlineCloudDownload size={23} />
        </DownloadButton>
      )}
    </Tippy>
  );
};
