import styled from "styled-components";

interface Props {
  height?: string;
  width?: string;
  border?: string;
  padding?: string;
}

export const Wrapper = styled.div<Props>`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
`;
