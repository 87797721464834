import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff137;
  height: 100%;
  display: grid;
  place-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  background-color: #ffffff;
  max-width: 500px;
  width: 500px;

  @media (max-width: 764px) {
    width: auto;
  }
`;

export const HeaderContent = styled.div`
  background-color: #000000;
  display: grid;
  place-items: center;
  padding: 1rem;
  gap: 0.5rem;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 4rem);
  padding: 2rem;
  padding-top: 1rem;
`;

export const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
`;
