import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const Eye = styled.div`
  cursor: pointer;
  position: absolute;

  right: 0%;
  top: 0%;
  border-start-end-radius: 5px;
  border-end-end-radius: 5px;

  transform: translate(0, 0);
  background-color: white;
  height: 100%;
  width: 32px;

  display: grid;
  place-items: center;
`;
