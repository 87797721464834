import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ValorTotalAccs = styled.span`
  margin-top: 0.75rem;
  font-size: 0.9rem;
  color: #8f8f8f;
  border-top: 0.125rem solid #ffd135;
  padding-left: 2rem;
  padding-right: 2rem;
  align-self: center;
  padding-top: 0.25rem;
`;
