import { Item, List, Title, Wrapper } from "./styles";

export const AspectosGenerales = () => {
  return (
    <Wrapper>
      <Title>ASPECTOS GENERALES:</Title>
      <List>
        <Item>
          La cuota de leasing incorpora, gatos de administración, y todos los
          gastos asociados a la operación.
        </Item>
        <Item>
          Cuota incorpora seguros para los bienes, todo el plazo del contrato.
        </Item>
        <Item>
          La presente es una simulación y está sujeta a aprobación por parte de
          nuestro Comité de Crédito. Esta tiene una validez de 07 dias.
        </Item>
      </List>
    </Wrapper>
  );
};
