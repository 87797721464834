//Libraries
import { FC } from "react";

//Components
import { PrimeraCuota } from "../../Molecules/PrimeraCuota/PrimeraCuota";
import { SimularBtn } from "../../Atoms/SimularBtn/SimularBtn";
import { Plazos } from "../../Molecules/Plazos/Plazos";
import { Field } from "../../Molecules/Field/Field";
import { Section } from "../Section/Section";
import { Icon } from "../../Atoms/Icon/Icon";
import { BackButton } from "../../Atoms/BackButton/BackButton";

import { Actions } from "../Simulacion/styles";

//Assets
import { FiTrendingUp } from "react-icons/fi";

export const DatosFinanciamiento: FC = () => {
  return (
    <Section
      title="Financiamiento"
      icon={
        <Icon
          padding="0.4rem"
          border="4px solid #ffffff"
          icon={<FiTrendingUp size={23} strokeWidth={1.5} />}
        />
      }
    >
      <Plazos />
      <Field label="Moneda" id="monedauf" uf />
      <Field label="Valor Total" id="valortotal" valortotal />
      <Field label="Pie" id="pie" pie />
      <PrimeraCuota />
      <SimularBtn />
      <Actions margin="1.75rem 0 0 0">
        <BackButton size={"sm"} />
      </Actions>
    </Section>
  );
};
