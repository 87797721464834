//Libraries
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

//Components
import { ModalError } from '../../Organisms/ModalError/ModalError'
import { Field } from '../Field/Field'

//Constants
import { SET_CALCULABLE } from '../../../App/constants/calculable'

import { fromXtoUF, TotalAccesorios as totalAccesorios } from '../../../helpers/calculate'

import { useFormatNumbers } from '../../../Hooks/useFormatNumbers'
import { useLoader } from '../../../Hooks/useLoader'

import { getMonedas } from '../../../Providers'

import { Moneda } from '../../../Interfaces'

export const Monedas = () => {
  const [monedas, setMonedas] = useState<Moneda[]>([])
  const { setValue, watch } = useFormContext()
  const { setLoadingTrue, setLoadingFalse } = useLoader()
  const { results } = useFormatNumbers()
  const dispatch = useDispatch()
  const { cantidad, valorunitarioneto, accesorios } = watch()
  /*  const { moneda: selectMoneda } = useSelector(
    ({ calculable }: any) => calculable
  )
 */
  useEffect(() => {
    //calculate()
    fetchMonedas()
    // eslint-disable-next-line
  }, [])

  const fetchMonedas = async () => {
    const { data } = await getMonedas()
    setMonedas(data)
  }

  /* const calculate = async () => {
    if (valorunitarioneto) {
      const valor_u_neto = results(valorunitarioneto)
      const ta = totalAccesorios(accesorios)
      const total = ta + cantidad * valor_u_neto.real

      try {
        setLoadingTrue()
        const total_uf = await fromXtoUF(Number(selectMoneda), total)
        setValue('total_uf', total_uf, { shouldValidate: true })
      } catch (error: any) {
        //@ts-ignore
        const { message } = error.response.data
        ModalError({
          text: message,
          confirmButtonText: 'Aceptar',
        })
      } finally {
        setLoadingFalse()
      }
    }
  }
 */
  const handleChange = async ({ idmoneda, nombre }: Moneda) => {
    const valor_u_neto = results(valorunitarioneto)
    const ta = totalAccesorios(accesorios)
    const total = ta + cantidad * valor_u_neto.real

    dispatch({
      type: SET_CALCULABLE,
      payload: {
        moneda: idmoneda,
      },
    })

    try {
      setLoadingTrue()
      const total_uf = await fromXtoUF(Number(idmoneda), total)
      setValue('total_uf', total_uf, { shouldValidate: true })
      setValue('moneda', idmoneda, { shouldValidate: true })
    } catch (error: any) {
      const { message } = error.response.data
      ModalError({
        text: message,
        confirmButtonText: 'Aceptar',
      })
    } finally {
      setLoadingFalse()
    }
  }

  const getMoneda = () => {
    const id = watch('moneda')
    return monedas.find((m: any) => m.idmoneda === id)?.nombre
  }

  return (
    <Field
      id="moneda"
      label="Moneda"
      dropdown={monedas}
      promt={getMoneda() ? getMoneda() : 'PESOS'}
      dropdownChange={handleChange}
      zIndex={999}
    />
  )
}
