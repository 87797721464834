//Components
import { Image } from "../../Atoms/Image/Image";
import { Text } from "../../Atoms/Text/Text";

//Styled Components
import { TextContainer, Version, VerticalLine, Wrappper } from "./styles";

//Assets
import FooterLogo from "../../../assets/images/logofooter.png";

export const Footer = () => {
  return (
    <Wrappper>
      <Image src={FooterLogo} alt="Footer Logo" height="62" />
      <VerticalLine />
      <TextContainer>
        <Text color="#ffffff" margin="0">
          contactenos@progreso.cl
        </Text>
        <Text color="#ffffff" margin="0">
          www.progreso.cl
        </Text>
      </TextContainer>
      <Version>v1.0.23</Version>
    </Wrappper>
  );
};
