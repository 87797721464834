import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 14px;
  margin: 0;
`;
