import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Input } from '../../Atoms/Input/Input'
import { Error } from '../Field/styles'

import { Container, Eye, Wrapper } from './styles'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

interface Props {
  id: string
  width?: string
  handleChange?: (e: any) => void
  placeholder?: string
}

export const PasswordField: FC<Props> = ({
  id,
  width,
  placeholder,
  handleChange,
}) => {
  const [show, setShow] = useState(false)

  const {
    formState: { errors },
  } = useFormContext()

  const error = errors[id]

  return (
    <Wrapper>
      <Container>
        <Input
          id={id}
          type={show ? 'text' : 'password'}
          width={width}
          //@ts-ignore
          error={error}
          handleChange={handleChange}
          placeholder={placeholder}
        />
        <Eye onClick={() => setShow((prev) => !prev)}>
          {show ? (
            <AiOutlineEyeInvisible size={25} color="#6c6b6b" />
          ) : (
            <AiOutlineEye size={25} color="#6c6b6b" />
          )}
        </Eye>
      </Container>
      <Error style={{ width: '250px', marginTop: '0.1rem' }}>
        {error && error.message}
      </Error>
    </Wrapper>
  )
}
