import { Simulacion } from "../../Interfaces/Simulacion.interface";
import { SET_COMBO } from "../constants/combo";

type BienCombo =
  | [
      {
        id: number;
        nombre: string;
      }
    ]
  | [];

interface IState {
  bienes: BienCombo;
  cotizaciones: Simulacion[];
}

interface Action {
  type: string;
  payload: { bienes: BienCombo; simulaciones: Simulacion[] };
}

const initialState: IState = { bienes: [], cotizaciones: [] };

export function comboReducer(state: IState = initialState, action: Action) {
  if (action.type === SET_COMBO) {
    return {
      ...state,
      ...action.payload,
    };
  } else return state;
}
