//Libraries
import { FC } from "react";
import { useFormContext } from "react-hook-form";

//Components
import { CelularField } from "../../Molecules/CelularField/CelularField";
import { RutField } from "../../Molecules/RutField/RutField";
import { Field } from "../../Molecules/Field/Field";
import { Section } from "../Section/Section";
import { Icon } from "../../Atoms/Icon/Icon";

import { RutContainer } from "./styles";

import { FiUser } from "react-icons/fi";

export const DatosCliente: FC = () => {
  const { getValues } = useFormContext();

  const personaJuridica = getValues("personajuridica");

  const width = document.getElementById("root")?.offsetWidth || 0;

  return (
    <Section
      title="Datos del Cliente"
      icon={
        <Icon
          padding="0.4rem"
          border="4px solid #ffffff"
          icon={<FiUser size={25} strokeWidth={1.5} />}
        />
      }
      margin="0 0 1rem 0"
      marginTitle="0 0 0 0"
    >
      <RutContainer>
        <RutField />
        {personaJuridica === 1 && (
          <Field
            label="Razón Social"
            id="razonsocial"
            width={width > 768 ? "482px" : "183px"}
          />
        )}
      </RutContainer>

      <Field label="Nombre" id="nombre" />
      <Field label="Correo" id="correo" />

      <CelularField />
    </Section>
  );
};
