//Libraries
import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";

//Styled Components
import { InputStyle } from "./styles";

interface Props {
  id: string;
  max?: string;
  min?: string;
  type?: string;
  width?: string;
  error?: boolean;
  step?: string;
  onBlur?: (e?: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Input: FC<Props> = ({
  id: idschema,
  max,
  min,
  type,
  error,
  step,
  width,
  onBlur,
  disabled,
  maxLength: maxLengthSchema,
  placeholder,
  handleChange,
  onFocus,
}) => {
  const { register, setValue } = useFormContext();

  const validateMaxLength = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value, maxLength } = e.target;
    const el = document.getElementById(id);
    if (maxLength > 0) {
      if (value.length > maxLength) {
        if (el instanceof HTMLInputElement)
          el.value = value.slice(0, maxLength);
      }
    }
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(idschema, e.target.value, { shouldValidate: true });
    if (onBlur) onBlur(e);
  };

  const isLogin = idschema === "loginpassword" || idschema === "loginemail";

  return (
    <InputStyle
      id={idschema}
      type={type}
      onInput={validateMaxLength}
      error={error}
      width={width}
      {...register(idschema)}
      disabled={disabled}
      readOnly={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      maxLength={maxLengthSchema}
      onBlur={handleBlur}
      max={max}
      min={min}
      step={step}
      isLogin={isLogin}
      onFocus={onFocus}
    />
  );
};
