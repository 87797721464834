//Libraries
import { useSelector } from "react-redux";
import { FiClipboard } from "react-icons/fi";

//Components
import { AspectosGenerales } from "../../Molecules/AspectosGenerales/AspectosGenerales";
import { SendFicha } from "../../Molecules/SendFicha/SendFicha";
import { BackButton } from "../../Atoms/BackButton/BackButton";
import { Download } from "../../Molecules/Download/Download";
import { Cart } from "../../Molecules/Cart/Cart";
import { Section } from "../Section/Section";
import { Icon } from "../../Atoms/Icon/Icon";

import { Actions, Border, Content } from "./styles";

export const Simulacion = () => {
  const { idsimulacion, correo } = useSelector(
    (state: any) => state.simulacion
  );
  return (
    <Section
      title="Simulación"
      icon={
        <Icon
          icon={<FiClipboard size={25} strokeWidth={1.5} />}
          padding="0.4rem"
          border="2px solid #ffffff"
        />
      }
      isSimulacion
    >
      <Border>
        <Content>
          <Cart plazo />
          <Cart />
        </Content>

        <AspectosGenerales />
      </Border>

      {idsimulacion !== 0 && (
        <Actions>
          <BackButton size={"lg"} />
          <Download size="lg" />
          <SendFicha email={correo} idsimulacion={idsimulacion} size="lg" />
        </Actions>
      )}
    </Section>
  );
};
