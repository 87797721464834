import styled from "styled-components";

export const Thead = styled.thead`
  background-color: #eceaea;
  color: #595d6e;

  tr {
    th {
      font-size: 0.85rem;
      padding: 1rem;
      font-weight: 500;
      min-width: 90px;
    }
  }
 
`;
