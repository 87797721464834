import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  background-color: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.primary.main};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 155px;
  margin-bottom: 2rem;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
  width: 70%;
  text-align: center;
  padding-bottom: 0.8rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: end;
`;

export const Number = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
`;

export const Text = styled.p`
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
  padding-bottom: 0.6rem;
  padding-left: 0.1rem;
`;

export const UF = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
  padding-left: 0.5rem;
`;
