// Libraries
import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'

// Components
import { Input } from '../../Atoms/Input/Input'
import { Dropdown } from '../Dropdown/Dropdown'
import { MonedaUF } from '../MonedaUF/MonedaUF'
import { Select } from '../../Atoms/Select/Select'
import { ValorTotal } from '../ValorTotal/ValorTotal'
import { TextArea } from '../../Atoms/TextArea/TextArea'
import { PagoInicial } from '../PagoInicial/PagoInicial'
import { ValorTotalNeto } from '../ValorTotalNeto/ValorTotalNeto'

// Styled components
import { Container, Error, Label, Lure, Span, Wrapper } from './styles'
import { useFormatNumbers } from '../../../Hooks/useFormatNumbers'

interface Props {
  label: string
  select?: Array<any>
  width?: string
  textarea?: boolean
  id: string
  total?: boolean
  pie?: boolean
  info?: { id: number; name: string }
  type?: string
  uf?: boolean
  valortotal?: boolean
  disabled?: boolean
  labelWidth?: string
  inputWidth?: string
  maxLength?: number
  placeholder?: string
  last?: boolean
  valorTotalAcc?: string
  onBlur?: (e?: ChangeEvent<HTMLInputElement>) => void
  promt?: string
  dropdown?: Array<any>
  step?: string
  zIndex?: number
  dropdownChange?: (opt: any) => void
  handleChange?: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
}

export const Field: FC<Props> = ({
  label,
  select,
  width,
  textarea,
  id,
  handleChange,
  total,
  pie,
  info,
  type,
  uf,
  valortotal,
  disabled,
  dropdown,
  dropdownChange,
  onBlur,
  labelWidth,
  valorTotalAcc,
  promt,
  maxLength,
  inputWidth,
  placeholder,
  zIndex,
  last,
  step,
}) => {
  const {
    formState: { errors },
  } = useFormContext()
  const { results } = useFormatNumbers()

  //@ts-ignore
  const isError =
    //@ts-ignore
    info &&
    //@ts-ignore
    errors['accesorios'] &&
    //@ts-ignore
    errors['accesorios'][info.id] &&
    //@ts-ignore
    errors['accesorios'][info.id][info.name]

  const isLogin = id.includes('login')

  return (
    <Wrapper
      last={last}
      style={{
        width: textarea ? width : 'auto',
        alignItems: textarea ? 'normal' : 'center',
      }}
    >
      <Container>
        <Label style={{ width: labelWidth }} length={label.length} isLogin={isLogin}>
          {label}
        </Label>

        {select ? (
          <Select id={id} optionList={select} width={width} handleChange={handleChange} />
        ) : dropdown && dropdownChange && promt ? (
          <Dropdown
            zindex={zIndex}
            options={dropdown}
            handleChange={dropdownChange}
            promt={promt}
            width={width}
          />
        ) : textarea ? (
          <TextArea id={id} />
        ) : total ? (
          <ValorTotalNeto />
        ) : pie ? (
          <PagoInicial id={id} />
        ) : uf ? (
          <MonedaUF />
        ) : valortotal ? (
          <ValorTotal />
        ) : (
          <Input
            id={id}
            error={errors[id] ? true : isError ? true : false}
            width={width ? width : '147px'}
            handleChange={handleChange}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={onBlur}
            step={step}
          />
        )}
        {valorTotalAcc && valorTotalAcc !== '0' && !isError ? (
          <Span>{`Sub Total: $${results(valorTotalAcc).format}`}</Span>
        ) : null}
      </Container>

      {
        //@ts-ignore
        !info && errors && errors[id] ? (
          //@ts-ignore
          <Error>{errors[id].message}</Error>
        ) : info ? null : (
          <Lure />
        )
      }

      {isError ? (
        //@ts-ignore
        <Error>{errors['accesorios'][info.id][info.name].message}</Error>
      ) : info ? (
        <Lure />
      ) : null}
    </Wrapper>
  )
}
