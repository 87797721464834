import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Field } from "../../Molecules/Field/Field";

import { Form, Title, Wrapper } from "./styles";
import { Submit } from "../LoginForm/styles";

import { updatePassword } from "../../../Providers/Usuario/Usuario.provider";

import { SET_LOADING } from "../../../App/constants/loading";
import { EncryptData } from "../../../helpers/encrypt";
import { SwalModal } from "../../Molecules/SwalModal/SwalModal";

export const NuevaPassword = () => {
  const usuario = JSON.parse(localStorage.getItem("user") || "{}");
  const idusuario = parseInt(usuario.idusuario) || -1;
  const dispatch = useDispatch();

  const schema = Yup.object()
    .shape({
      password: Yup.string()
        .required("Ingresa tu contraseña actual")
        .min(6, "La contraseña debe tener minimo 6 caracteres")
        .matches(/[0-9]/, "La contraseña debe tener al menos un numero")
        .matches(
          /[A-Z]/,
          "La contraseña debe tener al menos una letra mayúscula"
        )
        .matches(
          /[a-z]/,
          "La contraseña debe tener al menos una letra minúscula"
        ),
      new_password: Yup.string()
        .required("Ingresar nueva contraseña")
        .min(6, "La contraseña debe tener minimo 6 caracteres")
        .matches(/[0-9]/, "La contraseña debe tener al menos un numero")
        .matches(
          /[A-Z]/,
          "La contraseña debe tener al menos una letra mayúscula"
        )
        .matches(
          /[a-z]/,
          "La contraseña debe tener al menos una letra minúscula"
        ),
      newpassword: Yup.string()
        .required("Ingresar nueva contraseña")
        .equals([Yup.ref("new_password")], "Las contraseñas no coinciden"),
    })
    .required();

  const methods = useForm({ resolver: yupResolver(schema), mode: "all" });
  const {
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handlePasswords = (e: any) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldValidate: true });
    setValue("new_password", getValues("new_password"), {
      shouldValidate: true,
    });
  };

  const onSubmit = async (data: any) => {
    dispatch({ type: SET_LOADING, payload: true });
    const { password, new_password } = data;

    const { public_key } = JSON.parse(localStorage.getItem("user") || "{}");

    try {
      const status = await fetchSubmit(
        EncryptData(password, public_key),
        EncryptData(new_password, public_key)
      );
      if (status === 200) {
        SwalModal().fire({
          title: "Contraseña actualizada",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        SwalModal().fire({
          title: "Error",
          text: "No se pudo actualizar la contraseña",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#202020",
        });
      }
    } catch (error) {
      SwalModal().fire({
        title: "Error",
        text: "No se pudo actualizar la contraseña",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#202020",
      });
    } finally {
      reset();
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const fetchSubmit = async (password: string, new_password: string) => {
    const { status } = await updatePassword({
      idusuario,
      password,
      new_password,
    });
    return status;
  };

  return (
    <Wrapper>
      <Title>Nueva Contraseña</Title>
      <FormProvider {...methods}>
        <Form
          id="changepasswordform"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
        >
          <Field id="password" label="Actual Contraseña" width="200px" />
          <Field
            id="new_password"
            label="Nueva Contraseña"
            width="200px"
            handleChange={handlePasswords}
          />
          <Field
            id="newpassword"
            label="Confirmar"
            width="200px"
            handleChange={handlePasswords}
          />
        </Form>
        <Submit
          form="changepasswordform"
          style={{ width: "230px", alignSelf: "center", margin: "1rem" }}
          disabled={!isValid}
        >
          Cambiar Contraseña
        </Submit>
      </FormProvider>
    </Wrapper>
  );
};
