import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { formateaRut } from "../../../helpers/validate";

import { Input } from "../../Atoms/Input/Input";

interface Props {
  id: string;
  width: string;
}

export const RutInput: FC<Props> = ({ id, width }) => {
  const { setValue, getValues } = useFormContext();

  return (
    <Input
      id={id}
      width={width}
      maxLength={12}
      handleChange={(e) => {
        const { value } = e.target;
        setValue(id, formateaRut(value, getValues(id)));
      }}
    />
  );
};
