import Swal from "sweetalert2";

export const SwalModal = () => {
  return Swal.mixin({
    customClass: {
      container: "swal-container",
      confirmButton: "btn-success",
      cancelButton: "btn-cancel",
    },
  });
};
