import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eceaea;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-top: 80px;

  @media (max-width: 1325px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
