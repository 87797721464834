import styled from "styled-components";

interface Props {
  last?: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
  gap: 10px;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 764px) {
    justify-content: center;
  }
  position: relative;
`;

interface LabelProps {
  isLogin: boolean;
  length: number;
}

export const Label = styled.label<LabelProps>`
  font-size: 14px;
  color: #6c6b6b;
  text-align: end;
  min-width: 88px;

  @media (max-width: 764px) {
    width: 115px;
    font-size: 13.5px;
    text-align: left;
  }

  @media (max-width: 410px) {
    width: 95px;
    font-size: 12.5px;
  }

  @media (max-width: 3700px) {
    ${({ isLogin }) => isLogin && `min-width: 0px !important; width: 0px; `}
  }
`;

export const Lure = styled.div`
  height: 17px;
`;

export const Error = styled.p`
  width: 92%;
  margin: 0;
  color: red;
  font-size: 11.5px;
  text-align: end;
`;

export const Span = styled.span`
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 0.75rem;
  color: #8f8f8f;
`;
