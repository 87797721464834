import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.main};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Line = styled.div`
  width: 45px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 5px;
`;

export const Children = styled.div`
  background-color: #ffffff;
  box-shadow: 5px 0px 80px #898989;
`;
