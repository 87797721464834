import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AiOutlineUser } from 'react-icons/ai'

import { Actions, Button, FullName, List, ListItem, Section, SectionContent } from '../ModalMenu/styles'

export const MenuUsuario = () => {
  const navigate = useNavigate()

  const { nombres, correo, celular, tipo_usuario } = useSelector((state: any) => state.main)

  const closeSession = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('temporal')

    navigate('/logout')
  }

  return (
    <>
      <Section border>
        <AiOutlineUser
          style={{
            borderRadius: '50%',
            background: '#F7CF46',
            padding: '0.4rem',
          }}
        />
        <SectionContent>
          <FullName>{nombres}</FullName>
          <List>
            <ListItem>
              <strong>Email: </strong>
              {correo}
            </ListItem>
            <ListItem>
              <strong>Fono: </strong>
              {celular}
            </ListItem>
          </List>
        </SectionContent>
        <Actions>
          <Button onClick={() => navigate('updateprofile', { replace: false })}>Actualizar Datos</Button>
          <Button onClick={() => navigate('changepassword', { replace: false })}>Cambiar Contraseña</Button>
          {tipo_usuario === 2 && (
            <Button onClick={() => navigate('/mantenedor/bandeja/usuarios')}>Mantenedor de usuarios</Button>
          )}
        </Actions>
      </Section>
      <Section center>
        <Button onClick={closeSession} style={{ width: '180px' }}>
          Cerrar Sesión
        </Button>
      </Section>
    </>
  )
}
