// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/* export const validaRut = (obj) => {
  var largo, crut, rut, dv, i, suma, mul, res, dvi
  var tmpstr = ''
  var intlargo = obj

  if (obj.length < 11) return false

  if (intlargo.length > 0) {
    crut = obj
    largo = crut.length
    if (largo < 2) {
      return false
    }
    for (i = 0; i < crut.length; i++)
      if (crut.charAt(i) !== ' ' && crut.charAt(i) !== '.' && crut.charAt(i) !== '-') {
        tmpstr = tmpstr + crut.charAt(i)
      }
    rut = tmpstr
    crut = tmpstr
    largo = crut.length

    if (largo > 2) rut = crut.substring(0, largo - 1)
    else rut = crut.charAt(0)

    dv = crut.charAt(largo - 1)

    if (rut == null || dv == null) return 0

    var dvr = '0'
    suma = 0
    mul = 2

    for (i = rut.length - 1; i >= 0; i--) {
      suma = suma + rut.charAt(i) * mul
      if (mul === 7) mul = 2
      else mul++
    }

    res = suma % 11
    if (res === 1) dvr = 'k'
    else if (res === 0) dvr = '0'
    else {
      dvi = 11 - res
      dvr = dvi + ''
    }

    if (dvr !== dv.toLowerCase()) return false

    return true
  }
} */

//@ts-nocheck
export const validaRut = (obj: any) => {
  var largo, crut, rut, dv, i, suma, mul, res, dvi
  var tmpstr = ''
  var intlargo = obj
  if (intlargo.length > 0) {
    crut = obj
    largo = crut.length
    if (largo < 2) {
      return false
    }
    for (i = 0; i < crut.length; i++)
      if (crut.charAt(i) !== ' ' && crut.charAt(i) !== '.' && crut.charAt(i) !== '-') {
        tmpstr = tmpstr + crut.charAt(i)
      }

    rut = tmpstr
    crut = tmpstr
    largo = crut.length

    if (largo > 2) rut = crut.substring(0, largo - 1)
    else rut = crut.charAt(0)

    dv = crut.charAt(largo - 1)

    if (rut == null || dv == null) return 0

    var dvr = '0'
    suma = 0
    mul = 2

    for (i = rut.length - 1; i >= 0; i--) {
      //@ts-nocheck
      suma = suma + rut.charAt(i) * mul
      if (mul === 7) mul = 2
      else mul++
    }

    res = suma % 11
    if (res === 1) dvr = 'k'
    else if (res === 0) dvr = '0'
    else {
      dvi = 11 - res
      dvr = dvi + ''
    }

    if (dvr !== dv.toLowerCase()) {
      return false
    }
    return true
  }
}

export const formateaRut = (act: string, pre: string) => {
  let actual = act.replaceAll(' ', '')
  let prev = pre.replaceAll(' ', '')
  if (actual !== undefined) {
    const actualLimp = actual.replace('.', '').replace('-', '')

    if (actual.length <= 8 && isNaN(actualLimp)) {
      return prev
    }
    if (actualLimp.replace('k', '').includes('k') || actualLimp.replace('K', '').includes('K')) {
      return prev
    }
    if (actual.length > 11 && !actualLimp.match(/[0-9Kk]$/)) {
      return prev
    }

    if (actual !== '' && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, '')
      var actualLimpio = sinPuntos.replace(/-/g, '')
      var inicio = actualLimpio.substring(0, actualLimpio.length - 1)
      var rutPuntos = ''
      var i = 0
      var j = 1
      for (i = inicio.length - 1; i >= 0; i--) {
        var letra = inicio.charAt(i)
        rutPuntos = letra + rutPuntos
        if (j % 3 === 0 && j <= inicio.length - 1) {
          rutPuntos = '.' + rutPuntos
        }
        j++
      }
      var dv = actualLimpio.substring(actualLimpio.length - 1)
      rutPuntos = rutPuntos + '-' + dv
      return rutPuntos
    }
  }
  return actual
}

export const formatCelular = (value: string, prev: string) => {
  const valuelimpio = value.split('-').join('')

  if (valuelimpio.match('^[0-9]*$')) {
    const cel = value.split('-')
    const cel_concat = cel.join('')

    const parte1 = cel_concat.slice(0, 3)
    const parte2 = cel_concat.slice(3, 6)
    const parte3 = cel_concat.slice(6, 9)

    if (value.length > 3 && value.length < 8) return `${parte1}-${parte2}`
    if (value.length > 7) return `${parte1}-${parte2}-${parte3}`
    return parte1
  } else return prev
}
