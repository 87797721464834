import styled, { createGlobalStyle, DefaultTheme } from "styled-components";

import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
    html, body{
        margin: 0;
    }

    * {
        font-family: 'Poppins', sans-serif;
      }
      .swal-container{
        z-index: 99999999 !important;
      }

      .btn-success{
        background-color: #F7CF46 !important;
        color: #1D1D1B !important;
        box-shadow: none !important;
        outline: none !important;
      }

      .btn-cancel{
        background-color: #1d1d1b !important;
        color: #ffffff !important;
        box-shadow: none !important;
      }
`;

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.main};
`;

export const theme: DefaultTheme = {
  colors,
};
