import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    img {
      :nth-child(1) {
        height: 64px;
      }
      :nth-child(2) {
        height: 48px;
      }
    }

    p {
      display: none;
    }
  }
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
`;
