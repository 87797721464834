import styled from 'styled-components'

export const Wrapper = styled.table`
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  min-width: 100%;

  thead {
    background-color: #eceaea;
    color: #595d6e;

    tr {
      th {
        font-size: 0.85rem;
        padding: 1rem;
        font-weight: 500;
        min-width: 90px;
      }
    }
  }

  tbody {
    tr {
      :hover {
        background-color: #e4e4e4;
      }
    }

    td {
      border-bottom: 1px solid #e5e5e5;
      padding: 0.5rem;
      font-size: 13px;
      text-align: center;
      min-width: 90px;
      color: #595d6e;

      p {
        margin: 0;
      }

      @media (max-width: 764px) {
        font-size: 12px;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pagination {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    button {
      display: grid;
      place-items: center;
      border: none;
      padding: 0.42rem;
      font-size: 0.8rem;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      border-radius: 1rem;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      color: ${({ theme }) => theme.colors.dark.main};

      :hover {
        background-color: ${({ theme }) => theme.colors.secondary.main};
      }
    }

    .numbers {
      display: flex;
      justify-content: center;
    }
  }
`
