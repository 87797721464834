import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { Simulacion } from "../../../Interfaces/Simulacion.interface";

import { CotizacionRow } from "../../Molecules/CotizacionRow/CotizacionRow";
import { TableHead } from "../../Molecules/TableHead/TableHead";
import { ModalFiltrar } from "../../Molecules/ModalFiltrar/ModalFiltrar";
import { ModalReportar } from "../ModalReportar/ModalReportar";

import { FaFilter } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import {
  Bottom,
  Button,
  ButtonFiltrar,
  ButtonNumber,
  ButtonReportar,
  ButtonsContainer,
  Container,
  ContainerTable,
  Numbers,
  Table,
  Tbody,
} from "./styles";

import { SET_FILTRO } from "../../../App/constants/filtro";

interface Props {
  grilla: Simulacion[];
}

export const TableCotizaciones: FC<Props> = ({ grilla }) => {
  const [paginatedItems, setPaginatedItems] = useState<Simulacion[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenFiltrar, setIsOpenFiltrar] = useState(false);
  const [isOpenModalReportar, setIsOpenModalReportar] = useState(false);
  const dispatch = useDispatch();
  const rowPerPage = 8;

  const totalPages = grilla ? Math.ceil(grilla.length / rowPerPage) : 0;
  const pages = _.range(1, totalPages + 1);

  useEffect(() => {
    const _pagination = _(grilla)
      .slice((currentPage - 1) * rowPerPage)
      .take(rowPerPage)
      .value();
    setPaginatedItems(_pagination);
  }, [grilla, currentPage]);

  const pagination = (nroPage: number) => {
    setCurrentPage(nroPage);

    const startIndex = (nroPage - 1) * rowPerPage;
    const paginated = _(grilla).slice(startIndex).take(rowPerPage).value();

    setPaginatedItems(paginated);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      pagination(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      pagination(currentPage + 1);
    }
  };

  const closeModal = () => {
    dispatch({
      type: SET_FILTRO,
      payload: "",
    });
    setIsOpenModalReportar(false);
    setIsOpenFiltrar(false);
  };

  return (
    <ContainerTable>
      <ButtonsContainer>
        <ButtonFiltrar onClick={() => setIsOpenFiltrar(true)}>
          <FaFilter /> <span>Filtrar</span>
        </ButtonFiltrar>
        <ButtonReportar onClick={() => setIsOpenModalReportar(true)}>
          <RiFileExcel2Fill size={15} color="#000000" />
          <span>Generar Reporte</span>
        </ButtonReportar>
      </ButtonsContainer>

      <Container>
        <Table>
          <TableHead />
          <Tbody>
            {paginatedItems.map((cotizacion) => (
              <CotizacionRow
                simulacion={cotizacion}
                key={`key-cot-${cotizacion.idsimulacion}`}
              />
            ))}
          </Tbody>
        </Table>
      </Container>
      <Bottom>
        <Button onClick={handlePrev}>
          <MdOutlineKeyboardArrowLeft size={18} />
        </Button>
        <Numbers>
          {currentPage - 3 >= 1 && (
            <>
              <ButtonNumber
                onClick={() => pagination(1)}
                isActive={currentPage === 1}
              >
                {1}
              </ButtonNumber>
              <span>...</span>
            </>
          )}
          {pages?.map((page, index) => {
            const isInRange =
              currentPage - 1 <= page && currentPage + 1 >= page;

            return isInRange ? (
              <ButtonNumber
                key={index}
                onClick={() => pagination(page)}
                isActive={currentPage === page}
              >
                {page}
              </ButtonNumber>
            ) : null;
          })}
          {currentPage + 3 <= totalPages && (
            <>
              <span>...</span>
              <ButtonNumber
                onClick={() => pagination(pages.length)}
                isActive={currentPage === totalPages}
              >
                {pages.length}
              </ButtonNumber>
            </>
          )}
        </Numbers>
        <Button onClick={handleNext}>
          <MdOutlineKeyboardArrowRight size={18} />
        </Button>
      </Bottom>

      {isOpenFiltrar && <ModalFiltrar closeModal={closeModal} />}
      {isOpenModalReportar && <ModalReportar closeModal={closeModal} />}
    </ContainerTable>
  );
};
