//Libraries
import { combineReducers, createStore } from "redux";

//Reducers
import { calculableReducer } from "./reducers/calculableReducer";
import { simularReducer } from "./reducers/simulacionReducer";
import { clienteReducer } from "./reducers/clienteReducer";
import { loadingReducer } from "./reducers/loadingReducer";
import { filtroReducer } from "./reducers/filtroReducer";
import { marcaReducer } from "./reducers/marcaReducer";
import { comboReducer } from "./reducers/comboReducer";
import { bienReducer } from "./reducers/bienReducer";
import { userReducer } from "./reducers/userReducer";
import { defaultValuesReducer } from "./reducers/defaultValuesReducer";
import { mainReducer } from "./reducers/mainReducer";

const rootReducer = createStore(
  combineReducers({
    cliente: clienteReducer,
    bien: bienReducer,
    calculable: calculableReducer,
    marca: marcaReducer,
    simulacion: simularReducer,
    loading: loadingReducer,
    combo: comboReducer,
    filtro: filtroReducer,
    user: userReducer,
    default: defaultValuesReducer,
    main: mainReducer,
  })
);

export default rootReducer;
