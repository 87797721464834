//Libraries
import { FC, ReactNode } from "react";

//Styled Compoonents
import { Content, Title, Wrapper } from "./styles";

interface Props {
  title: string;
  isHidden?: boolean;
  children: ReactNode;
  padding?: string;
  titleSize?: string;
  icon?: JSX.Element;
  margin?: string;
  marginTitle?: string;
  isSimulacion?: boolean;
}

export const Section: FC<Props> = ({
  title,
  children,
  isHidden,
  padding,
  titleSize,
  icon,
  margin,
  isSimulacion,
  marginTitle,
}) => {
  return (
    <Wrapper isHidden={isHidden} margin={margin} isSimulacion={isSimulacion}>
      <Title style={{ fontSize: titleSize, margin: marginTitle }}>
        &nbsp;&nbsp; {icon}
        {title}
      </Title>
      <Content padding={padding}>{children}</Content>
    </Wrapper>
  );
};
