import { Usuario } from "../../Interfaces/Usuario.interface";
import { SET_MAIN } from "../constants/main";

const initialState: Usuario = {
  idusuario: 0,
  idproveedor: 0,
  nombres: "",
  tipo_usuario: 0,
  celular: "",
  correo: "",
};

export function mainReducer(
  state: Usuario = initialState,
  action: any
): Usuario {
  switch (action.type) {
    case SET_MAIN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
