import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_LOADING } from "../../../App/constants/loading";
import { updateUser } from "../../../Providers/Usuario/Usuario.provider";
import { CelularField } from "../../Molecules/CelularField/CelularField";

import { Field } from "../../Molecules/Field/Field";
import { SwalModal } from "../../Molecules/SwalModal/SwalModal";

import { Submit } from "../LoginForm/styles";
import { Form, Title } from "../NuevaPassword/styles";
import { Wrapper } from "./styles";

export const ActualizarDatos = () => {
  const usuario = JSON.parse(localStorage.getItem("user") || "{}");
  const idusuario = parseInt(usuario.idusuario) || -1;
  const { nombres, celular } = useSelector((state: any) => state.main);
  const dispatch = useDispatch();

  const schema = Yup.object()
    .shape({
      nombre: Yup.string()
        .required("Ingresar nombre")
        .min(5, "Minimo 5 caracteres"),
      celular: Yup.string()
        .required("Ingresar Celular")
        .test({
          test: (celular) => {
            if (celular?.length === 11) return true;
            else return false;
          },
          message: "El celular debe tener 9 dígitos",
        }),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      nombre: nombres,
      celular: celular,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = async (data: any) => {
    dispatch({ type: SET_LOADING, payload: true });
    const { celular } = data;
    const celularLimpio = celular.split("-").join("");

    try {
      const status = await fetchUpdate({ ...data, celular: celularLimpio });
      if (status === 200) {
        SwalModal().fire({
          title: "Datos actualizados",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        SwalModal().fire({
          title: "Error al actualizar datos",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      SwalModal().fire({
        title: "Error al actualizar datos",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    dispatch({ type: SET_LOADING, payload: false });
  };

  const fetchUpdate = async (body: { nombre: string; celular: string }) => {
    const { status } = await updateUser({ idusuario, ...body });
    return status;
  };
  return (
    <Wrapper>
      <Title>Actualizar Datos</Title>
      <FormProvider {...methods}>
        <Form
          id="updateprofile-form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
        >
          <Field id="nombre" label="Nombre" width="200px" />
          <CelularField width="200px" />
        </Form>
        <Submit
          form="updateprofile-form"
          style={{
            width: "230px",
            alignSelf: "center",
            margin: "1rem",
            fontWeight: "600",
          }}
          disabled={!isValid}
        >
          Actualizar Datos
        </Submit>
      </FormProvider>
    </Wrapper>
  );
};
