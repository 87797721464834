//Libraries
import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";

//Hooks
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";
import { useLoader } from "../../../Hooks/useLoader";

//Components
import { Icon } from "../../Atoms";
import { Section } from "../Section/Section";
import { Accesorios } from "../Accesorios/Accesorios";
import {
  Bien,
  CantidadBien,
  Deducibles,
  Field,
  Marcas,
  Monedas,
} from "../../Molecules";

//Helpers
import { fromXtoUF, TotalAccesorios } from "../../../helpers/calculate";

//Assets
import { FiFile } from "react-icons/fi";
import { ModalError } from "../ModalError/ModalError";
import { FieldNumber } from "../../Molecules/FieldNumber";

export const DatosBien: FC = () => {
  const { results } = useFormatNumbers();
  const { setValue, watch } = useFormContext();
  const { setLoadingTrue, setLoadingFalse } = useLoader();
  const { cantidad, valorunitarioneto, accesorios, moneda } = watch();

  const valor_u_neto = results(String(valorunitarioneto));
  const subtotal = Number((cantidad * valor_u_neto.real).toFixed(2));
  const ta = TotalAccesorios(accesorios);
  const total = ta + subtotal;

  //const isEditing = valorunitarioneto > 0 && cantidad > 0

  //don't touch: moneda, setValue, total
  const updateTotalUf = async () => {
    try {
      const total_uf = await fromXtoUF(moneda, total);
      setValue("total_uf", total_uf, { shouldValidate: true });
    } catch (error: any) {
      const { message } = error.response.data;
      ModalError({
        text: message,
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleBlur = async (e: any) => {
    try {
      setLoadingTrue();
      const { value, id } = e.target;
      setValue(id, value, { shouldValidate: true });

      await updateTotalUf();
    } catch (error) {
    } finally {
      setLoadingFalse();
    }
  };

  /*  useEffect(() => {
    const updateTotalUf = async () => {
      const total_uf = await fromXtoUF(moneda, total)
      setValue('total_uf', total_uf, { shouldValidate: true })
    }

    if (isEditing) updateTotalUf()
  }, [isEditing, moneda, setValue, total]) */

  const handleChangeCalculable = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { id, value } = e.target;

    setValue("valor_total", total, { shouldValidate: true });
    setValue(id, value, { shouldValidate: true });
  };

  return (
    <Section
      title="Datos del Bien"
      icon={
        <Icon
          padding="0.4rem"
          border="4px solid #ffffff"
          icon={<FiFile size={25} strokeWidth={1.33} />}
        />
      }
    >
      <Bien />
      <Marcas />
      <Field label="Modelo" id="modelo" />
      <Field
        label="Año"
        labelWidth="69px"
        id="anho"
        type="number"
        maxLength={4}
      />
      <CantidadBien
        handleChange={handleChangeCalculable}
        handleBlur={handleBlur}
      />
      <Monedas />
      <Deducibles />
      <FieldNumber
        label="Valor U. Neto"
        id="valorunitarioneto"
        onFocus={() => setValue("isdisabled", true)}
        handleChange={(e) => {
          setValue("valorunitarioneto", results(e.target.value).format);
          handleChangeCalculable(e);
        }}
        onBlur={(e) => {
          handleBlur(e);
          setValue("isdisabled", false);
        }}
      />
      <Field label="Valor T. Neto" id="valortotalneto" total type="text" />
      <Accesorios />
      <Field label="Glosa  (opcional) ." textarea width="99%" id="glosa" />
    </Section>
  );
};
