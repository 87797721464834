import styled from 'styled-components'

interface Props {
  row?: boolean
  widthFull?: boolean
}

export const Form = styled.form<Props>`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  width: ${(props) => (props.widthFull ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
`

export const Submit = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.dark.main};
  border: none;
  padding: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 7px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  width: 228px;
  font-family: 'Poppins', sans-serif;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }

  :disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`
