import { FC, ReactNode } from "react";

import { Wrapper } from "./styles";

interface Props {
  children: ReactNode;
  margin?: string;
  filtrar?: boolean;
}

export const Modal: FC<Props> = ({ children, margin, filtrar }) => {
  return <Wrapper margin={margin} filtrar={filtrar}>{children}</Wrapper>;
};
