import styled from "styled-components";

interface Props {
  margin?: string;
  filtrar?: boolean;
}

export const Wrapper = styled.div<Props>`
  position: fixed;
  width: 300px;
  right: 0%;
  top: 0%;
  transform: translate(-0%, -0%);
  height: 100%;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.15);
  margin-top: 90px;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;
