import { SwalModal } from "../../Molecules/SwalModal/SwalModal";

import WarningIcon from "../../../assets/images/warning.png";

interface Props {
  title?: string;
  text: string;
  confirmButtonText: string;
}

export const ModalError = ({ title, text, confirmButtonText }: Props) => {
  return SwalModal().fire({
    title: title ? title : "Error",
    iconHtml: `<img src="${WarningIcon}" alt="warning" width="100px" height="100px" />`,
    text,
    iconColor: "#ffffff",
    confirmButtonText: confirmButtonText,
  });
};
