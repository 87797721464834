import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { Text } from "../../Atoms/Text/Text";
import { Modal } from "../../Atoms/Modal/Modal";
import { FieldFiltro } from "../FieldFiltro/FieldFiltro";
import { CloseButton } from "../../Atoms/CloseButton/CloseButton";
import { ModalError } from "../../Organisms/ModalError/ModalError";

import { SET_COMBO } from "../../../App/constants/combo";

import { useLoader } from "../../../Hooks/useLoader";
import { useAppDispatch } from "../../../Hooks/useDispatch";
import { Form, Submit, Title, Wrapper } from "../ModalFiltrar/styles";
import {
  proveedoresCombo,
  usersBandeja,
  getPublicKey,
} from "../../../Providers";
import { DecryptData } from "../../../helpers/encrypt";
import { formatCelular, formateaRut } from "../../../helpers/validate";

interface Props {
  closeModal: () => void;
  updateData: (data: any) => void;
}
const tipos = [
  { id: 1, nombre: "Usuario" },
  { id: 2, nombre: "Administrador" },
];
const estados = [
  { id: 1, nombre: "Activos" },
  { id: 2, nombre: "Inactivos" },
];
export const FiltrarUsuarios: FC<Props> = ({ closeModal, updateData }) => {
  const { dispatch } = useAppDispatch();
  const { setLoadingTrue, setLoadingFalse } = useLoader();
  const [proveedores, setProveedores] = useState([
    { id: 0, nombre: "-- Seleccionar --" },
  ]);

  const idusuario =
    parseInt(JSON.parse(localStorage.getItem("user") || "{}").idusuario) || -1;

  const schema = yup.object().shape({}).required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { handleSubmit, watch, setValue } = methods;

  const onSubmit = async (formdata: any) => {
    try {
      setLoadingTrue();

      const grilla = await fetchData(formdata);

      if (grilla.length < 1) {
        ModalError({
          text: "No se encontraron resultados",
          confirmButtonText: "Aceptar",
        });

        return;
      }

      //fetch public key
      const { data: publicKey } = await getPublicKey(idusuario);

      //decrypt data fields encrypteds
      const rows = grilla.map((item: any) => {
        item.rut = DecryptData(item.rut, publicKey);
        item.nombres = DecryptData(item.nombres, publicKey);
        item.telefono = DecryptData(item.telefono, publicKey);

        return {
          id: item.idusuario,
          rut: formateaRut(item.rut, item.rut),
          nombres: item.nombres,
          telefono: formatCelular(item.telefono, item.telefono),
          proveedor: item.nombreProveedor || "No asignado",
          estado: item.estado,
        };
      });

      updateData(rows);

      dispatch(SET_COMBO, {
        simulaciones: rows,
      });
    } catch (error) {
      ModalError({
        text: "No se encontraron usuarios",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoadingFalse();
    }
  };

  const fetchData = async (params: any) => {
    const { data } = await usersBandeja(idusuario, params);
    return data;
  };

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickClose = (e: any) => {
      const isOutside =
        modalRef.current && !modalRef.current.contains(e.target);
      if (isOutside) closeModal();
    };
    fetchProveedores();
    setTimeout(() => {
      document.addEventListener("click", clickClose);
    }, 50);

    return () => document.removeEventListener("click", clickClose);
  }, [closeModal]);

  const fetchProveedores = async () => {
    const { data } = await proveedoresCombo();
    setProveedores(data);
  };
  const handleChangeProveedor = (e: any) => {
    setValue("idproveedor", e.id, { shouldValidate: true });
  };
  const getNameProveedorSelected = () => {
    const id = watch("idproveedor");
    return proveedores.find((proveedor: any) => proveedor.id === id)?.nombre;
  };
  const handleChangeTipo = (e: any) => {
    setValue("tipo", e.id, { shouldValidate: true });
  };
  const getNameTipoSelected = () => {
    const id = watch("tipo");
    return tipos.find((tipo: any) => tipo.id === id)?.nombre;
  };
  const handleChangeEstado = (e: any) => {
    setValue("estado", e.id, { shouldValidate: true });
  };
  const getNameEstadoSelected = () => {
    const id = watch("estado");
    return estados.find((estado: any) => estado.id === id)?.nombre;
  };
  return (
    <Modal filtrar>
      <Wrapper ref={modalRef}>
        <Title>
          <Text margin="0 0 0 15px">Ingresa tus Filtros</Text>
          <CloseButton handleClick={closeModal} />
        </Title>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldFiltro id="rut" label="Rut" type="rut" />

            <FieldFiltro
              id="idproveedor"
              label="Proveedor"
              type="dropdown"
              promt={getNameProveedorSelected() || "-- Seleccionar --"}
              options={proveedores}
              handleChange={handleChangeProveedor}
              zIndex={4}
            />
            <FieldFiltro
              id="estado"
              label="Estado"
              type="dropdown"
              promt={getNameEstadoSelected() || "-- Seleccionar --"}
              options={estados}
              handleChange={handleChangeEstado}
              zIndex={3}
            />
            <FieldFiltro
              id="tipo"
              label="Tipo"
              type="dropdown"
              promt={getNameTipoSelected() || "-- Seleccionar --"}
              options={tipos}
              handleChange={handleChangeTipo}
              zIndex={2}
            />

            <Submit>BUSCAR</Submit>
          </Form>
        </FormProvider>
      </Wrapper>
    </Modal>
  );
};
