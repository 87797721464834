//Libraries
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Field } from "../Field/Field";

//Interfaces
import { IBien } from "../../../Interfaces/Bien.interface";

//Actions
import { SET_CALCULABLE } from "../../../App/constants/calculable";
import { SET_LOADING } from "../../../App/constants/loading";
import { RESET_BIEN } from "../../../App/constants/bien";

//Providers
import { getBienById } from "../../../Providers/Bien/Bien.provider";

//Helpers
import { fromXtoUF } from "../../../helpers/calculate";

export const Bien = () => {
  const dispatch = useDispatch();
  const { setValue, getValues } = useFormContext();
  const { bienes } = useSelector((state: any) => state.combo);
  const calculable = useSelector((state: any) => state.calculable);
  const id = getValues("bien");

  useEffect(() => {
    const preLoad = async () => {
      if (id > -1) {
        try {
          dispatch({
            type: SET_LOADING,
            payload: true,
          });
          //fetch
          const bien = await fetchBien(id);
          setValue("pie", bien.pie_minimo);
          //set state
          dispatch({
            type: "SET_BIEN",
            payload: { ...bien, pie_minimo_min: bien.pie_minimo },
          });
        } catch (error) {
          //error
        } finally {
          dispatch({
            type: SET_LOADING,
            payload: false,
          });
        }
      }
    };

    preLoad();

    return () => {
      dispatch({
        type: RESET_BIEN,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleChange = async (opt: any) => {
    const bien: IBien = await fetchBien(opt.id);
    bien.accesorios === null && (bien.accesorios = []);
    dispatch({ type: "SET_BIEN", payload: bien });
    setValue("accesorios", []);
    setValue("deducibles", -1);
    setValue("bien", opt.id, { shouldValidate: true });
    setValue("pie", bien.pie_minimo);
    dispatch({
      type: SET_CALCULABLE,
      payload: {
        accesorios: [],
      },
    });

    const { cantidad, valorunitarioneto, moneda } = calculable;
    const total = cantidad * valorunitarioneto;

    const total_uf = await fromXtoUF(moneda, total);

    dispatch({
      type: SET_CALCULABLE,
      payload: {
        total_pesos: total,
        total_uf,
      },
    });
  };

  const fetchBien = async (idbien: number) => {
    const { data } = await getBienById(idbien);
    return data;
  };

  const getNombreBien = () => {
    return bienes.find((bien: any) => bien.id === id)?.nombre;
  };

  return (
    <Field
      id="bien"
      label="Bien"
      dropdown={bienes}
      promt={getNombreBien() ? getNombreBien() : "-- Seleccionar --"}
      dropdownChange={handleChange}
      zIndex={2}
    />
  );
};
