import styled from "styled-components";

export const Form = styled.form`
  display: grid;
  place-items: center;
  gap: 0.75rem;
  padding: 1.25rem;
`;

export const Bottom = styled.form`
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
`;
