import { useDispatch } from "react-redux";
import { SET_LOADING } from "../App/constants/loading";

// use dispatch type SET_LOADING
export const useLoader = () => {
  const dispatch = useDispatch();

  const setLoadingTrue = () => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
  };

  const setLoadingFalse = () => {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };

  return { setLoadingTrue, setLoadingFalse };
};
