//Libraries
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

//Components
import { Field } from "../Field/Field";

//Interfaces
import { IMarca } from "../../../Interfaces/Marca.interface";

//Providers
import { getMarcas } from "../../../Providers/Marca/Marca.provider";

//Constants
import { SET_MARCA } from "../../../App/constants/marca";

export const Marcas = () => {
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const [marcas, setMarcas] = useState<IMarca[]>([]);

  useEffect(() => {
    const fetchMarcas = async () => {
      const { data } = await getMarcas();
      dispatch({
        type: SET_MARCA,
        payload: { marcas: data },
      });
      setMarcas(data);
    };
    fetchMarcas();

    return () => {
      setMarcas([]);
    };
  }, [dispatch]);

  const handleChange = (opt: any) => {
    setValue("marca", opt.id, { shouldValidate: true });
  };

  const getMarca = () => {
    const id = watch("marca");
    return marcas.find((marca: any) => marca.id === id)?.nombre;
  };

  return (
    <Field
      id="marca"
      label="Marca"
      dropdown={marcas}
      promt={getMarca() ? getMarca() : "-- Seleccionar --"}
      dropdownChange={handleChange}
    />
  );
};
