import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const Button = styled.button`
  background-color: #f6d254;
  display: flex;
  border-radius: 8px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0 15px 0 15px;
  cursor: pointer;
  margin: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  :hover {
    background-color: #f6d220;
  }
`;
