import { Action } from "redux";

import { SET_USER } from "../constants/user";

interface IAction extends Action {
  payload: IUser;
}

export interface IUser {
  idusuario: number;
  public_key: string;
  token: string;
}

const initialState: IUser = {
  idusuario: 0,
  public_key: "",
  token: "",
};

export function userReducer(state = initialState, action: IAction): IUser {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
