import { FaSpinner } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Wrapper } from "./styles";

export const Loading = () => {
  const loading = useSelector((state: any) => state.loading);
  return (
    <Wrapper style={{ display: loading ? "flex" : "none" }}>
      <FaSpinner />
      <p>. . . Cargando</p>
    </Wrapper>
  );
};
