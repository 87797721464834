import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import {
  createUsuario,
  getPublicKey,
  getUsuarioById,
  proveedoresCombo,
  updateUsuario,
} from '../../../Providers'

import { CelularField, Field, RutField, SwalModal } from '../../../Components/Molecules'
import { Form, ModalError, Section, Submit } from '../../../Components/Organisms'
import { GoButton } from '../../../Components/Atoms/GoButton'

import { MainLayout } from '../../../Layouts/Main'

import { Wrapper } from '../../Home/styles'

import { formatCelular, formateaRut, validaRut } from '../../../helpers/validate'
import { DecryptData } from '../../../helpers/encrypt'
import { UpdateUsuario } from '../../../Interfaces'

export const Usuario = () => {
  const [proveedores, setProveedores] = useState([{ id: 0, nombre: '-- Seleccionar --' }])
  const tipos = [
    { id: 1, nombre: 'Usuario' },
    { id: 2, nombre: 'Administrador' },
  ]
  const { idusuario: idparam } = useParams()
  const lsUser = localStorage.getItem('user') || '{}'
  const idusuario = parseInt(JSON.parse(lsUser).idusuario)

  const schema = Yup.object()
    .shape({
      rut: Yup.string()
        .required('Ingresar RUT')
        .test({
          test: (rut) => {
            if (validaRut(rut)) return true
            else return false
          },
          message: 'Rut inválido',
        }),
      nombre: Yup.string().required('Ingrese nombre'),
      correo: Yup.string()
        .required('Ingresar correo')
        .email('El correo no es válido')
        .test({
          test: (val) => {
            if (val) {
              const valids = /^[a-zA-Z@.-_]+$/
              const no_valid_symbols = val.includes('--') || val.includes('__') || val.includes('..')

              if (val.match(valids) && !no_valid_symbols) return true
              return false
            }
            return false
          },
          message: 'Caracteres no válidos',
        }),
      password: !idparam
        ? Yup.string()
            .required('Ingresar nueva contraseña')
            .min(6, 'La contraseña debe tener minimo 6 caracteres')
            .matches(/[0-9]/, 'La contraseña debe tener al menos un numero')
            .matches(/[A-Z]/, 'La contraseña debe tener al menos una letra mayúscula')
            .matches(/[a-z]/, 'La contraseña debe tener al menos una letra minúscula')
        : Yup.string(),
      confirmPassword: !idparam
        ? Yup.string()
            .required('Ingresar nueva contraseña')
            .equals([Yup.ref('password')], 'Las contraseñas no coinciden')
        : Yup.string(),
      celular: Yup.string()
        .required('Ingresar Celular')
        .test({
          test: (celular) => {
            if (celular?.length === 11) return true
            else return false
          },
          message: 'El celular debe tener 9 dígitos',
        }),
      tipo: Yup.number()
        .required('Escoger tipo')
        .typeError('Escoger tipo')
        .test({
          test: (val) => {
            if (val) return true
            return false
          },
        }),
      proveedor: Yup.number()
        .required('Escoger proveedor')
        .typeError('Escoger proveedor')
        .test({
          test: (val) => {
            if (val) return true
            return false
          },
        }),
    })
    .required()

  const methods = useForm({ mode: 'all', resolver: yupResolver(schema) })

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = methods

  const fetchProveedores = async () => {
    const { data } = await proveedoresCombo()
    setProveedores(data)
  }

  const llenarFormulario = async () => {
    if (!idparam) return

    const { data: publicKey } = await getPublicKey(idusuario)
    const { data } = await getUsuarioById(parseInt(idparam))

    //Decrypt data
    const { rut, nombres, correo, celular, tipo_usuario, idproveedor } = data
    setValue('rut', formateaRut(DecryptData(rut, publicKey), '.'))
    setValue('nombre', DecryptData(nombres, publicKey))
    setValue('correo', DecryptData(correo, publicKey))
    setValue('celular', formatCelular(DecryptData(celular, publicKey), '-'))
    setValue('tipo', tipo_usuario)
    setValue('proveedor', idproveedor)
  }

  useEffect(() => {
    fetchProveedores()
    llenarFormulario()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data: any) => {
    delete data.confirmPassword
    delete data.razonsocial
    delete data.personajuridica

    const body = {
      rut: data.rut,
      nombres: data.nombre,
      correo: data.correo,
      password: data.password,
      telefono: data.celular,
      tipo_usuario: data.tipo,
      idproveedor: data.proveedor,
      idusuario,
    }

    try {
      if (idparam) {
        const bori: UpdateUsuario = {
          idusuario: parseInt(idparam),
          rut: data.rut,
          nombres: data.nombre,
          correo: data.correo,
          idproveedor: data.proveedor,
          telefono: data.celular,
          tipo_usuario: data.tipo,
          idcrea: idusuario,
        }

        await updateUsuario(bori)
      } else await createUsuario(body)

      SwalModal().fire({
        title: 'Usuario creado',
        text: `El usuario ha sido ${idparam ? 'actualizado' : 'creado'} correctamente`,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        showConfirmButton: true,
      })
    } catch (error: any) {
      ModalError({
        title: 'Error',
        text: error.response.data.message || `Error al ${idparam ? 'actualizar' : 'crear'} usuario`,
        confirmButtonText: 'Aceptar',
      })
    }
  }

  const handleChangeTipo = (e: any) => {
    setValue('tipo', e.id, { shouldValidate: true })
  }

  const handleChangeProveedor = (e: any) => {
    setValue('proveedor', e.id, { shouldValidate: true })
  }

  const getNameTipoSelected = () => {
    const id = watch('tipo')
    return tipos.find((tipo: any) => tipo.id === id)?.nombre
  }

  const getNameProveedorSelected = () => {
    const id = watch('proveedor')
    return proveedores.find((proveedor: any) => proveedor.id === id)?.nombre
  }

  return (
    <MainLayout>
      <Wrapper>
        <Section title="Mantenedor de Usuarios">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <GoButton text="Regresar a la bandeja" to="/mantenedor/bandeja/usuarios" />
          </div>

          <FormProvider {...methods}>
            <Form widthFull onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: idparam ? 'column' : 'row', flexWrap: 'wrap' }}>
                  <RutField />
                  <Field id="nombre" label="Nombre" />
                  <CelularField labelWidth={idparam ? '' : '155px'} />
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Field id="correo" label="Correo" />
                  {!idparam && (
                    <>
                      <Field id="password" type="password" label="Contraseña" />
                      <Field id="confirmPassword" type="password" label="Confirmar contraseña" />
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: !idparam ? 'row' : 'column',
                    flexWrap: 'wrap',
                    width: !idparam ? '100%' : 'auto',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Field
                    id="tipo"
                    label="Tipo"
                    promt={getNameTipoSelected() || '-- Seleccionar --'}
                    dropdown={tipos}
                    dropdownChange={handleChangeTipo}
                    zIndex={2}
                  />
                  <Field
                    id="proveedor"
                    label="Proveedor"
                    promt={getNameProveedorSelected() || '-- Seleccionar --'}
                    dropdown={proveedores}
                    dropdownChange={handleChangeProveedor}
                  />
                </div>
              </div>
              <Submit type="submit" disabled={!isValid}>
                Crear usuario
              </Submit>
            </Form>
          </FormProvider>
        </Section>
      </Wrapper>
    </MainLayout>
  )
}
