import { FC, forwardRef, Ref } from "react";
import { Arrow, Title, Wrapper } from "./styles";

import ArrowIcon from "../../../assets/svg/arrow-bottom.svg";
import VerticalLine from "../../../assets/svg/vertical-line.svg";

interface Props {
  onClick: () => void;
  isOpen: boolean;
  prompt?: string;
  ref: any;
}

export const Control: FC<Props> = forwardRef(
  (props, ref: Ref<HTMLDivElement>) => {
    const { isOpen, onClick, prompt } = props;

    return (
      <Wrapper onClick={onClick} ref={ref}>
        <Title>{prompt ? prompt : "-- Seleccionar --"}</Title>
        <Arrow>
          <img src={VerticalLine} alt="Vertical Line" />
          <img
            src={ArrowIcon}
            alt="Arrow Icon"
            style={{
              transform: `${isOpen ? "rotate(180deg)" : "rotate(0deg)"} `,
            }}
          />
        </Arrow>
      </Wrapper>
    );
  }
);
