//Libraries
import { useEffect } from 'react'
import { Routes as Switch, Route } from 'react-router-dom'

//Components
import { PrivateRoute } from '../Components/Molecules/PrivateRoute/PrivateRoute'

import { Home } from '../Pages/Home'
import { Login } from '../Pages/Login'
import { Cotizar } from '../Pages/Cotizar'
import { MissPassword } from '../Pages/MissPasword'
import { RecoverPassword } from '../Pages/RecoverPassword'

import { SET_MAIN } from '../App/constants/main'
import { SET_COMBO } from '../App/constants/combo'
import { SET_SIMULAR } from '../App/constants/simular'

import { useAppDispatch } from '../Hooks/useDispatch'
import { useLoader } from '../Hooks/useLoader'

import { getPublicKey } from '../Providers/Key/Key.provider'
import { getBienesCombo } from '../Providers/Bien/Bien.provider'
import { getMainUsuario } from '../Providers/Usuario/Usuario.provider'
import { getSimulacion } from '../Providers/Simulaciones/Simulaciones.provider'

import { DecryptData } from '../helpers/encrypt'
import { subtractMonth } from '../helpers/calculate'
import { BandejaUsuarios } from '../Pages/Mantenedor/Bandeja/BandejaUsuarios'
import { Usuario } from '../Pages/Mantenedor/Formulario/Usuario'

export const Routes = () => {
  const { dispatch } = useAppDispatch()
  const { setLoadingTrue, setLoadingFalse } = useLoader()

  const actualdate = new Date().toLocaleDateString('en-CA')
  const lsUser = localStorage.getItem('user') || '{}'
  const idusuario = parseInt(JSON.parse(lsUser).idusuario)

  useEffect(() => {
    if (idusuario) {
      fetchMain()
      fetchBienes()
      fetchSimulaciones()
    }
  })

  const fetchBienes = async () => {
    const { data } = await getBienesCombo()
    dispatch(SET_COMBO, {
      bienes: data,
    })
  }

  const fetchSimulaciones = async () => {
    try {
      setLoadingTrue()
      const { data } = await getSimulacion(idusuario, {
        fechaInicio: subtractMonth(actualdate),
        fechaFin: actualdate,
      })

      dispatch(SET_SIMULAR, {
        idsimulacion: 0,
      })

      dispatch(SET_COMBO, {
        simulaciones: data.grilla,
      })
    } catch (error) {
      //Error message
    } finally {
      setLoadingFalse()
    }
  }

  const fetchPublicKey = async () => {
    const { data } = await getPublicKey(idusuario)
    return data
  }

  const fetchMain = async () => {
    try {
      setLoadingTrue()
      const { data } = await getMainUsuario(idusuario)
      const { usuario } = data

      const publicKey = await fetchPublicKey()

      const user = {
        idusuario: usuario.idusuario,
        nombres: DecryptData(usuario.nombres, publicKey),
        idproveedor: usuario.idproveedor,
        tipo_usuario: usuario.tipo_usuario,
        celular: DecryptData(usuario.celular, publicKey),
        correo: DecryptData(usuario.correo, publicKey),
      }

      dispatch(SET_MAIN, user)
    } catch (error) {
      //Error message
    } finally {
      setLoadingFalse()
    }
  }

  return (
    <Switch>
      <Route element={<Login />} path="/login" />

      <Route
        path="//*"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        path="/cotizar/*"
        element={
          <PrivateRoute>
            <Cotizar />
          </PrivateRoute>
        }
      />

      <Route element={<MissPassword />} path="/misspassword" />

      <Route element={<RecoverPassword />} path="/recoverpassword" caseSensitive={false} />

      <Route path="/mantenedor/bandeja/usuarios" element={<BandejaUsuarios />} />

      <Route path="/mantenedor/formulario/usuarios/:idusuario" element={<Usuario />} />

      <Route path="/mantenedor/formulario/usuarios" element={<Usuario />} />

      <Route
        path="*"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
    </Switch>
  )
}
