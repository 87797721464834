import { Field } from "../Field/Field";

export const PrimeraCuota = () => {
  return (
    <Field
      label="Primera cuota (días)"
      id="primeracuotatiempo"
      type="number"
      disabled
      last
    />
  );
};
