import { SET_LOADING } from "../constants/loading/index";

type loadingState = boolean;

const initialState: loadingState = false;

export function loadingReducer(state: loadingState = initialState, action: any) {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;
    default:
      return initialState;
  }
}
