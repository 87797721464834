//Libraries
import { FC } from 'react'

//Components
import { FieldNumber } from '../FieldNumber'

interface Props {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void
  handleBlur: (e?: any) => void
}

export const CantidadBien: FC<Props> = ({ handleChange, handleBlur }) => {
  return (
    <FieldNumber
      label="Cantidad"
      id="cantidad"
      maxLength={3}
      handleChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
