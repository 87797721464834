import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IoMdSwitch } from "react-icons/io";
import { AiOutlineForm } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { GoButton } from "../../../Components/Atoms/GoButton";
import { SwalModal } from "../../../Components/Molecules";
import {
  ButtonFiltrar,
  ButtonsContainer,
  ModalError,
  Section,
} from "../../../Components/Organisms";
import { Table } from "../../../Components/Organisms/Table";
import { Actions } from "../../../Components/Organisms/Simulacion/styles";

import { DecryptData } from "../../../helpers/encrypt";
import { formatCelular, formateaRut } from "../../../helpers/validate";

import { MainLayout } from "../../../Layouts/Main";

import {
  activarUsuario,
  desactivarUsuario,
  getPublicKey,
  usersBandeja,
} from "../../../Providers";

import { Wrapper } from "../../Home/styles";
import WarningIcon from "../../../assets/images/warning.png";
import { BackButton } from "../../../Components/Atoms";
import Tippy from "@tippyjs/react";
import { FiltrarUsuarios } from "../../../Components/Molecules/FiltrarUsuarios";
import { FaFilter } from "react-icons/fa";

export const BandejaUsuarios = () => {
  const [users, setUsers] = useState([{}]);
  const [isOpenFiltrar, setIsOpenFiltrar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    "Nro.",
    "RUT",
    "Nombre",
    "Telefono",
    "Proveedor",
    "Estado",
    "Acciones",
  ];

  const lsUser = localStorage.getItem("user") || "{}";
  const idusuario = parseInt(JSON.parse(lsUser).idusuario);

  const actions = [
    {
      label: (
        <Tippy content="Editar">
          <div>
            <AiOutlineForm style={{ cursor: "pointer" }} />
          </div>
        </Tippy>
      ),
      icon: "edit",
      action: (row: any) => {
        navigate(`/mantenedor/formulario/usuarios/${row.id}`);
      },
    },
    {
      label: (
        <Tippy content="Activar/Desactivar">
          <div>
            <IoMdSwitch
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </Tippy>
      ),
      icon: "switch",
      action: (row: any) => {
        enableOrDisable(row.id, row.estado);
      },
    },
  ];

  const enableOrDisable = (id: number, state: number) => {
    SwalModal()
      .fire({
        iconHtml: `<img src="${WarningIcon}" alt="warning" width="100px" height="100px" />`,
        iconColor: "#ffffff",
        confirmButtonColor: "#ff4735",
        confirmButtonText: `Si,  ${state !== 1 ? "activar" : "desactivar"}.`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        title: `${state !== 1 ? "Activar" : "Desactivar"} usuario`,
        text: `¿Seguro de ${state !== 1 ? "activar" : "desactivar"} usuario?`,
      })
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          try {
            dispatch({ type: "SET_LOADING", payload: true });
            if (state !== 1) await activarUsuario(id);
            else await desactivarUsuario(id);
            SwalModal()
              .fire({
                title: "¡Listo!",
                text: `${
                  state !== 1 ? "Usuario habilitado" : "Usuario deshabilitado"
                } con éxito`,
                icon: "success",
              })
              .then(() => {
                const newRows = users.map((user: any) => {
                  if (user.id === id) {
                    user.estado = state === 1 ? 2 : 1;
                  }
                  return user;
                });
                setUsers(newRows);
              });
          } catch (error: any) {
            ModalError({
              title: "Error",
              text:
                error.response.data.message ||
                "Error al habilitar/deshabilitar usuario",
              confirmButtonText: "Aceptar",
            });
          } finally {
            dispatch({ type: "SET_LOADING", payload: false });
          }
        }
      });
  };

  const fetchData = async () => {
    const user = localStorage.getItem("user");
    const iduser = JSON.parse(user || "").idusuario;

    //setLoading
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      const { data } = await usersBandeja(iduser);

      //fetch public key
      const { data: publicKey } = await getPublicKey(idusuario);
      //decrypt data fields encrypteds
      const rows = data.map((item: any) => {
        item.rut = DecryptData(item.rut, publicKey);
        item.nombres = DecryptData(item.nombres, publicKey);
        item.telefono = DecryptData(item.telefono, publicKey);

        return {
          id: item.idusuario,
          rut: formateaRut(item.rut, item.rut),
          nombres: item.nombres,
          telefono: formatCelular(item.telefono, item.telefono),
          proveedor: item.nombreProveedor || "No asignado",
          estado: item.estado,
        };
      });

      setUsers(rows);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = (newData: any) => {
    setUsers(newData);
  };
  return (
    <MainLayout>
      <Wrapper>
        <Section title="Mantenedor de Usuarios">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <GoButton
              text="Crear usuario"
              to="/mantenedor/formulario/usuarios"
            />
          </div>
          <ButtonsContainer>
            <ButtonFiltrar onClick={() => setIsOpenFiltrar(true)}>
              <FaFilter /> <span>Filtrar</span>
            </ButtonFiltrar>
          </ButtonsContainer>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Table dataHeader={columns} dataBody={users} actions={actions} />
          </div>

          <Actions>
            <BackButton size="sm" />
          </Actions>
        </Section>
        {isOpenFiltrar && (
          <FiltrarUsuarios
            updateData={updateData}
            closeModal={() => setIsOpenFiltrar(false)}
          />
        )}
      </Wrapper>
    </MainLayout>
  );
};
