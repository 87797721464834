import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  padding: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: 200ms;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`;
