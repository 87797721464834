//Libraries
import { useFormContext } from "react-hook-form";
import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

//Styles
import { Wrapper } from "./styles";

export const ValorTotalNeto = () => {
  const { watch, setValue } = useFormContext();
  const { results } = useFormatNumbers();

  const { cantidad, valorunitarioneto } = watch();

  let total = results(
    (cantidad * results(String(valorunitarioneto)).real).toFixed(2)
  );

  let total_format = total.format;

  const decimal = total_format.split(",");

  if (isNaN(results(String(valorunitarioneto)).real))
    setValue("valorunitarioneto", 0);

  if (decimal[1] && Number(decimal[1]) < 0.1)
    total_format = total_format.split(",")[0];

  return <Wrapper value={total_format} readOnly type="text" />;
};
