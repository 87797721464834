import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

export const Leyenda = styled.p`
  color: #828282;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 13.5px;
  }
`;
