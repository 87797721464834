import { IMarca } from "../../Interfaces/Marca.interface";
import { SET_MARCA } from "../constants/marca";

type MarcasState = { marcas: IMarca[] };

interface Action {
  type: string;
  payload: IMarca;
}

const initialState: MarcasState = {
  marcas: [],
};

export function marcaReducer(
  state: MarcasState = initialState,
  action: Action
) {
  switch (action.type) {
    case SET_MARCA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
