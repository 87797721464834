import styled from "styled-components";

export const StyledTextArea = styled.textarea`
  outline: none;
  color: #6c6b6b;
  resize: none;
  padding: 8px;
  border: none;
  box-shadow: 0px 0px 2px 0.4px #6c6b6b;
  border-radius: 5px;
  width: calc(100% - 100px);
  margin-top: 0.8rem;
  @media (max-width: 640px) {
    width: 167px;
  }
`;
