import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Text } from "../../Atoms/Text/Text";
import { Modal } from "../../Atoms/Modal/Modal";
import { FieldFiltro } from "../FieldFiltro/FieldFiltro";
import { CloseButton } from "../../Atoms/CloseButton/CloseButton";
import { ModalError } from "../../Organisms/ModalError/ModalError";

import { Form, Submit, Title, Wrapper } from "./styles";

import { subtractMonth } from "../../../helpers/calculate";

import { getSimulacion } from "../../../Providers/Simulaciones/Simulaciones.provider";

import { SET_COMBO } from "../../../App/constants/combo";
import { SET_FILTRO } from "../../../App/constants/filtro";

import { useLoader } from "../../../Hooks/useLoader";
import { useAppDispatch } from "../../../Hooks/useDispatch";

interface Props {
  closeModal: () => void;
}

export const ModalFiltrar: FC<Props> = ({ closeModal }) => {
  const { bienes } = useSelector((state: any) => state.combo);
  const { bienSelected } = useSelector((state: any) => state.filtro);
  const { dispatch } = useAppDispatch();
  const { setLoadingTrue, setLoadingFalse } = useLoader();

  const actualdate = new Date().toLocaleDateString("en-CA");
  const idusuario =
    parseInt(JSON.parse(localStorage.getItem("user") || "{}").idusuario) || -1;

  const schema = yup
    .object()
    .shape({
      fechaInicio: yup.string(),
      fechaFin: yup.string(),
      bien: yup.string(),
    })
    .required();

  const methods = useForm({
    defaultValues: {
      fechaInicio: subtractMonth(actualdate),
      fechaFin: actualdate,
      bien: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = async (formdata: any) => {
    setLoadingTrue();

    if (!formdata.idsimulacion) delete formdata.idsimulacion;
    formdata.bien = bienSelected;
    if (formdata.bien === "-- Seleccionar --") formdata.bien = "";

    try {
      const { grilla } = await fetchData(formdata);

      if (grilla.length < 1) {
        ModalError({
          text: "No se encontraron resultados",
          confirmButtonText: "Aceptar",
        });
        return;
      }

      dispatch(SET_COMBO, {
        simulaciones: grilla,
      });
    } catch (error) {
      ModalError({
        text: "No se encontraron cotizaciónes",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoadingFalse();
    }
  };

  const fetchData = async (params: any) => {
    const { data } = await getSimulacion(idusuario, params);
    return data;
  };

  const handleChange = (e: any) => {
    dispatch(SET_FILTRO, e.nombre);
  };

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickClose = (e: any) => {
      const isOutside =
        modalRef.current && !modalRef.current.contains(e.target);
      if (isOutside) closeModal();
    };

    setTimeout(() => {
      document.addEventListener("click", clickClose);
    }, 50);

    return () => document.removeEventListener("click", clickClose);
  }, [closeModal]);

  return (
    <Modal filtrar>
      <Wrapper ref={modalRef}>
        <Title>
          <Text margin="0 0 0 15px">Ingresa tus Filtros</Text>
          <CloseButton handleClick={closeModal} />
        </Title>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldFiltro
              id="idsimulacion"
              label="Nro. Simulación"
              type="number"
            />
            <FieldFiltro
              id="fechaInicio"
              label="Desde"
              type="date"
              max={actualdate}
            />
            <FieldFiltro
              id="fechaFin"
              label="Hasta"
              type="date"
              min={watch("fechaInicio")}
            />
            <FieldFiltro
              id="bien"
              label="Bien"
              type="dropdown"
              options={[{ id: -1, nombre: "-- Seleccionar --" }, ...bienes]}
              handleChange={handleChange}
            />
            <FieldFiltro id="cliente" label="Cliente" type="text" />
            <FieldFiltro id="rut" label="Rut" type="rut" />

            <Submit>BUSCAR</Submit>
          </Form>
        </FormProvider>
      </Wrapper>
    </Modal>
  );
};
