import styled from "styled-components";

export const Wrapper = styled.div`
  cursor: pointer;

  svg {
    transition: 200ms ease-in-out;
  }

  &:hover {
    svg {
      fill: #d7af28;
    }
  }
`;
