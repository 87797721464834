import { instance } from "../api";

import { Moneda } from "../../Interfaces/Monedas.interface";
import { Response } from "../../Interfaces/Response.interface";

type DataRes = Response<Moneda[]>;

export const getMonedas = async () => {
  const { data } = await instance.get<DataRes>("/monedas");
  return data;
};
