import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;

  div:nth-child(2) {
    svg {
      height: 32px;
      width: 32px;
    }
  }

  @media (max-width: 768px) {
    div:nth-child(2) {
      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;

  @media (max-width: 768px) {
    p:nth-child(1) {
      font-size: 11.5px;
      text-indent: 12px;
    }
    p:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 375px) {
    p {
      display: none;
    }
  }
`;
