import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-collapse: collapse;

  img {
    padding-top: 4px;
    margin: 10px;

    :hover {
      background-color: aliceblue;
      cursor: pointer;
    }
  }

  @media (max-width: 870px) {
    border: 1px solid #f6d254;
    flex-direction: column;

    .trash-icon {
      align-self: center;
    }
  }

  :nth-child(even) {
    border-bottom: 0;
  }

  :nth-child(odd){
    border-bottom: 0;
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 870px) {
    flex-direction: column;
  }
`;
