//Libraries
import { AxiosResponse } from "axios";

//Interfaces
import { IUf } from "../../Interfaces/Uf.interface";

//Configs
import { instance } from "../api";

type UfResponse = AxiosResponse<IUf>;

export const getUf = async (
  idmoneda: number,
  valor_total: number,
  idmoneda_financiamiento: number = 2
): Promise<UfResponse> => {
  const { data } = await instance.get(
    `Monedas/paridadFinanciamiento/${idmoneda}/${valor_total}/${idmoneda_financiamiento}`
  );
  return data;
};
