import { FC } from "react";
import Tippy from "@tippyjs/react";

import { SET_COMBO } from "../../../App/constants/combo";

import { subtractMonth } from "../../../helpers/calculate";

import {
  deleteSimulacion,
  getSimulacion,
} from "../../../Providers/Simulaciones/Simulaciones.provider";

import { SwalModal } from "../SwalModal/SwalModal";
import { ModalError } from "../../Organisms/ModalError/ModalError";

import { AiOutlineDelete } from "react-icons/ai";
import WarningIcon from "../../../assets/images/warning.png";

import { Wrapper } from "./styles";

import { useAppDispatch } from "../../../Hooks/useDispatch";
import { useLoader } from "../../../Hooks/useLoader";

interface Props {
  idsimulacion: number;
  idusuario: number;
  fecha: string;
  cliente: string;
}

export const DeleteCotizacion: FC<Props> = (props) => {
  const actualdate = new Date().toLocaleDateString("en-CA");

  const { setLoadingTrue, setLoadingFalse } = useLoader();
  const { dispatch } = useAppDispatch();

  const handleDelete = async () => {
    try {
      setLoadingTrue();

      await fetchDeleteSimulacion();
      await fetchSimulaciones();
    } catch (error: any) {
      const { message } = error.response.data;

      ModalError({
        text: message,
        confirmButtonText: "Aceptar",
      });
    } finally {
      setLoadingFalse();
    }
  };

  const fetchDeleteSimulacion = async () => {
    const { data } = await deleteSimulacion(props);
    return data;
  };

  const fetchSimulaciones = async () => {
    const { data } = await getSimulacion(props.idusuario, {
      fechaInicio: subtractMonth(actualdate),
      fechaFin: actualdate,
    });

    dispatch(SET_COMBO, {
      simulaciones: data.grilla,
    });
  };

  const showModal = async () => {
    const { isConfirmed } = await SwalModal().fire({
      iconHtml: `<img src="${WarningIcon}" alt="warning" width="100px" height="100px" />`,
      iconColor: "#ffffff",
      confirmButtonColor: "#ff4735",
      confirmButtonText: "Si, eliminar.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      title: "¿Seguro de eliminar permanentemente la cotización?",
      html: `<div style="border: 3px solid #eceaea; border-radius: 1rem;">
              <p style="margin: 0.5rem">Nro de Simulación: ${props.idsimulacion}</p>
              <p style="margin: 0.5rem">Cliente: ${props.cliente}</p>
              <p style="margin: 0.5rem">Fecha de Creación: ${props.fecha}</p>
            </div>
      `,
    });

    if (isConfirmed) await handleDelete();
  };

  return (
    <Tippy content="Eliminar">
      <Wrapper onClick={showModal}>
        <AiOutlineDelete size={23} color="#000000" />
      </Wrapper>
    </Tippy>
  );
};
