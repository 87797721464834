import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { StyledTextArea } from "./styles";

interface Props {
  id: string;
}

export const TextArea: FC<Props> = ({ id }) => {
  const { register } = useFormContext();

  return (
    <StyledTextArea
      {...register(id)}
      rows={3}
      placeholder="El bien incluye..."
    ></StyledTextArea>
  );
};
