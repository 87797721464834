import styled from "styled-components";

export const RutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: auto;
    flex-direction: column;
  }
`;
