//Libraries
import { FC } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";

//Components
import { Text } from "../../Atoms/Text/Text";

//Styled Components
import { Button, Wrapper } from "./styles";

//Assets
import { AiOutlinePlusCircle } from "react-icons/ai";

interface Props {
  addAccesorio: () => void;
}

export const AddAccesorio: FC<Props> = ({ addAccesorio }) => {
  const { accesorios } = useSelector((state: any) => state.bien);
  const accesoriosExists = accesorios.length > 1;

  return (
    <Wrapper>
      <Tippy
        content={
          !accesoriosExists &&
          "Este bien no cuenta con accesorios para agregar."
        }
        delay={!accesoriosExists ? [200, 100] : [2009999, 0]}
      >
        <div>
          <Button
            onClick={addAccesorio}
            type="button"
            disabled={!accesoriosExists}
          >
            <AiOutlinePlusCircle
              size={23}
              color={!accesoriosExists ? "#1010104d" : "black"}
            />
            <Text fontSize="14px">Agregar Accesorio</Text>
          </Button>
        </div>
      </Tippy>
    </Wrapper>
  );
};
