import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { Field } from "../Field/Field";

export const Deducibles = () => {
  const { getValues, setValue } = useFormContext();

  const { deducible } = useSelector((state: any) => state.bien);

  const handleChange = (opt: any) => {
    setValue("deducibles", opt.id, { shouldValidate: true });
  };

  const getDeducible = () => {
    const id = getValues("deducibles");
    return deducible.find((marca: any) => marca.id === id)?.nombre;
  };

  return (
    <Field
      label="Deducible"
      dropdownChange={handleChange}
      dropdown={deducible}
      promt={getDeducible() ? getDeducible() : "-- Seleccionar --"}
      id="deducibles"
    />
  );
};
