import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface PropsActions {
  margin?: string;
}

export const Actions = styled.div<PropsActions>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 5rem;
  ${({ margin }) => margin && `margin: ${margin}`};

  @media (max-width: 768px) {
    gap: 2rem;
  }
`;

export const Border = styled.div`
  border: 3px solid #eceaea;
  border-radius: 1rem;
  padding: 1.5rem;
  padding-bottom: 0;
  margin-bottom: 1.25rem;
`;
