import styled from "styled-components";

interface Props {
  size?: string;
}

export const Wrapper = styled.div<Props>`
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-color: #ffd135;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 250ms ease-in-out;
  width: ${({ size }) => size === "sm" && "50px"};
  height: ${({ size }) => size === "sm" && "50px"};
  background-color: ${({ size }) => size === "sm" && "#eceaea"};

  :hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    background-color: ${({ size }) => size === "sm" && "#ffd135"};
  }

  svg {
    transition: 200ms ease-in-out;
  }

  @media (max-width: 768px) {
    width: 55px;
    height: 55px;
  }
`;
