import { FC } from "react";

import { Wrapper } from "./styles";

interface Props {
  icon: JSX.Element;
  padding?: string;
  border?: string;
  onClick?: () => void;
  pointer?: boolean;
}

export const Icon: FC<Props> = ({
  icon,
  padding,
  border,
  onClick,
  pointer,
}) => {
  return (
    <Wrapper
      padding={padding}
      border={border}
      onClick={onClick}
      style={{ cursor: pointer ? "pointer" : "auto" }}
    >
      {icon}
    </Wrapper>
  );
};
