import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { MissPasswordLayout } from "../../Layouts/MissPassword";

import { SwalModal } from "../../Components/Molecules/SwalModal/SwalModal";
import { Submit } from "../../Components/Molecules/ModalFiltrar/styles";
import { Field } from "../../Components/Molecules/Field/Field";
import { Text } from "../../Components/Atoms/Text/Text";
import { ReCatpcha } from "../../Components/Molecules/ReCaptcha/ReCaptcha";
import { ModalError } from "../../Components/Organisms/ModalError/ModalError";

import { Back } from "../../Layouts/MissPassword/styles";
import { Container } from "../RecoverPassword/styles";
import { Form } from "./styles";
import ConfirmedIcon from "../../assets/images/confirmed.png";
import "./styles.css";

import {
  getTokenTemporal,
  sendUrlToMail,
} from "../../Providers/Usuario/Usuario.provider";

export const MissPassword = () => {
  const [token, setToken] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [captchaKey, setCaptchaKey] = useState<string | null>();
  const [wasError, setWasError] = useState(false);
  const navigate = useNavigate();

  const modalClasses = {
    image: "modal-image",
    title: "modal-title",
    actions: "actions",
    htmlContainer: "modal-text",
    confirmButton: "button",
  };

  const schema = Yup.object()
    .shape({
      logincorreo: Yup.string()
        .email("Correo no válido")
        .required("Ingresar correo"),
    })
    .required();

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = async () => {
    const { data } = await getTokenTemporal();
    setToken(data);
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
  } = methods;

  const handleBack = () => {
    navigate("/login");
  };

  const handleSubmitForm = async (data: any) => {
    const { logincorreo } = data;
    const body = {
      correo: logincorreo,
      captchatoken: captchaKey || "",
    };

    try {
      const status = await fetchSendUrlToMail(body);
      if (status === 200)
        SwalModal()
          .fire({
            title: "Recupera tu contraseña",
            text: "Si el correo existe en nuestros registros, se enviará un email de recuperación a su casilla.",
            imageUrl: ConfirmedIcon,
            imageHeight: "auto",
            imageWidth: 120,
            confirmButtonText: "Regresar al inicio de sesión",
            confirmButtonColor: "#ffd135",
            confirmButtonAriaLabel: "#000000",
            allowOutsideClick: false,
            customClass: modalClasses,
          })
          .then(() => navigate("/login"));
      else {
        showError();
      }
    } catch (error: any) {
      setWasError((v) => !v);
      const { message } = error.response.data;
      showError(message);
    }
  };

  const showError = (msg?: string) => {
    ModalError({
      title: "Credenciales inválidas",
      text: msg || "No se ha podido enviar el correo.",
      confirmButtonText: "Aceptar",
    });
  };

  const fetchSendUrlToMail = async (body: {
    correo: string;
    captchatoken: string;
  }) => {
    const { status } = await sendUrlToMail(body, token);
    return status;
  };

  return (
    <MissPasswordLayout>
      <Text margin="1.1rem 0 0 0" fontWeight="bold" fontSize="0.9rem">
        ¿Olvidaste tu contraseña?
      </Text>

      <Text margin="0" fontWeight="bold" fontSize="0.9rem">
        No te preocupes, aquí te ayudamos.
      </Text>

      <Container>
        <Text fontSize="0.85rem" align="center">
          Ingresa tu correo electrónico y te enviaremos un enlace para que
          puedas cambiar tu contraseña.
        </Text>
      </Container>
      <FormProvider {...methods}>
        <Form id="form_miss_password" onSubmit={handleSubmit(handleSubmitForm)}>
          <Field
            label=""
            placeholder="Correo"
            id="logincorreo"
            width="230px"
            handleChange={({ target }) =>
              setValue("logincorreo", target.value, { shouldValidate: true })
            }
          />

          <ReCatpcha
            setCheck={setIsCheck}
            setCaptchaKey={setCaptchaKey}
            wasError={wasError}
          />
        </Form>

        <Submit
          form="form_miss_password"
          disabled={isSubmitting || !isValid || !isCheck}
          style={{ fontSize: "1rem", fontWeight: "bold", marginTop: "0.8rem" }}
        >
          Enviar
        </Submit>
      </FormProvider>
      <Back>
        <Text color="#727272" fontSize="0.85rem" align="center">
          ¿Recordaste tu contraseña?
        </Text>
        <Text
          margin="0"
          fontWeight="bold"
          fontSize="0.85rem"
          onClick={handleBack}
          pointer
        >
          Entra aqui
        </Text>
      </Back>
    </MissPasswordLayout>
  );
};
