//Libraries
import { FC, useEffect, useRef, useState } from "react";

//Components
import { Control } from "../../Atoms/Control/Control";
import { Options } from "../Options/Options";

//Styles
import { Wrapper } from "./styles";

interface Props {
  promt?: string;
  options: Object[];
  width?: string;
  handleChange?: (opt: any) => void;
  zindex?: number;
}

export const Dropdown: FC<Props> = ({
  options,
  handleChange,
  promt,
  zindex,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", close);
    return () => document.removeEventListener("click", close);
  }, []);

  const close = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) setIsOpen(false);
  };

  return (
    <Wrapper width={width} style={{ zIndex: zindex }}>
      <Control
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        prompt={promt}
        ref={ref}
      />

      <Options
        options={options}
        isOpen={isOpen}
        prompt={promt || ""}
        setIsOpen={setIsOpen}
        handleChange={handleChange}
      />
    </Wrapper>
  );
};
