import styled from "styled-components";

interface Props {
  isOpen: boolean;
}

export const Wrapper = styled.div<Props>`
  display: ${({ isOpen }) => !isOpen && "none"};
  border: ${({ isOpen }) => (!isOpen ? "none" : "1px solid #c1c1c1")};
  width: 100%;
  border-collapse: collapse;
`;

interface OptionProps {
  isSelected: boolean;
  isDisabled: boolean;
}

export const Option = styled.div<OptionProps>`
  background-color: ${({ isDisabled }) => (isDisabled ? "#eceaea" : "#fff")};
  color: #6c6b6b;
  border-collapse: collapse;
  font-size: 13.5px;
  padding: 3px;
  border-bottom: 1px solid #c7c7c7;
  cursor: ${({ isDisabled }) => isDisabled && "not-allowed"};

  ${({ isSelected }) => isSelected && "background-color: #ffd135"};

  :hover {
    background-color: #ffd135;
  }
`;
