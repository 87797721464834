import { AxiosResponse } from "axios";

import { instance } from "../api";

import { IPlazo } from "../../Interfaces/Plazos.interface";

type PlazosResponse = AxiosResponse<IPlazo[]>;

export const getPlazos = async (): Promise<PlazosResponse> => {
  const { data } = await instance.get("Plazos/Combo");
  return data;
};
