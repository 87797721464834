import { FC } from "react";
import Tippy from "@tippyjs/react";

import { useFormatNumbers } from "../../../Hooks/useFormatNumbers";

import { Download } from "../Download/Download";
import { SendFicha } from "../SendFicha/SendFicha";

import { Actions, Bienes, Row, RowItem } from "./styles";

import { Simulacion } from "../../../Interfaces/Simulacion.interface";

import { DeleteCotizacion } from "../DeleteCotizacion/DeleteCotizacion";
import { EditCotizacion } from "../EditCotizacion/EditCotizacion";
import { ToolTipBienes } from "../ToolTipBienes/ToolTipBienes";

interface Props {
  simulacion: Simulacion;
}

export const CotizacionRow: FC<Props> = ({ simulacion }) => {
  const {
    idsimulacion,
    rut,
    cliente,
    cantidad,
    marca,
    modelo,
    anio,
    descripcion,
    valor_cuota,
    plazo,
    fecha,
    persona_juridica,
    razon_social,
    correo,
  } = simulacion;

  const { idusuario } = JSON.parse(localStorage.getItem("user") || "{}");
  const nombre =
    persona_juridica === 1 && razon_social.length > 0 ? razon_social : cliente;

  const { results } = useFormatNumbers();
  const valor_cuota_formated = results(String(valor_cuota));

  const contentBienes = {
    descripcion,
    marca,
    modelo,
    anio,
  };

  return (
    <Row>
      <RowItem>{idsimulacion}</RowItem>
      <RowItem>{rut}</RowItem>
      <RowItem
        title={nombre}
        style={{
          maxWidth: "280px",
        }}
      >
        {nombre}
      </RowItem>
      <RowItem>{cantidad}</RowItem>
      <RowItem>
        <Tippy content={<ToolTipBienes bien={contentBienes} />}>
          <Bienes>
            {descripcion}, Marca: {marca}, Modelo: {modelo}, Año: {anio}
          </Bienes>
        </Tippy>
      </RowItem>
      <RowItem>UF {valor_cuota_formated.format}</RowItem>
      <RowItem>{plazo}</RowItem>
      <RowItem>{fecha}</RowItem>
      <RowItem>
        <Actions>
          <Download size="sm" id={idsimulacion} />
          <SendFicha email={correo} idsimulacion={idsimulacion} />
          <DeleteCotizacion
            idsimulacion={idsimulacion}
            idusuario={idusuario}
            cliente={nombre}
            fecha={fecha}
          />
          <EditCotizacion idsimulacion={idsimulacion} idusuario={idusuario} />
        </Actions>
      </RowItem>
    </Row>
  );
};
