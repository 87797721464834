//Libraries
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

//Components
import { PasswordField } from '../../Molecules/PasswordField/PasswordField'
import { ReCatpcha } from '../../Molecules/ReCaptcha/ReCaptcha'
import { Field } from '../../Molecules/Field/Field'

//Styles
import { Form, Submit } from './styles'

import { ILogin } from '../../../Interfaces/Login.interface'

import { Login, preLogin } from '../../../Providers/Login/Login.provider'

import { EncryptData } from '../../../helpers/encrypt'

import { useLoader } from '../../../Hooks/useLoader'
import { ModalError } from '../ModalError/ModalError'

export const LoginForm = () => {
  const { setLoadingTrue, setLoadingFalse } = useLoader()

  const [isCheck, setIsCheck] = useState(false)
  const [captchaKey, setCaptchaKey] = useState<string | null>()
  const [wasError, setWasError] = useState(false)

  const schema = yup
    .object()
    .shape({
      loginemail: yup
        .string()
        .email('El correo no es válido')
        .required('Ingresar correo'),
      loginpassword: yup.string().required('Ingresar contraseña'),
    })
    .required()

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods

  const onSubmit = async (data: any) => {
    setLoadingTrue()
    const { loginemail, loginpassword } = data

    try {
      const token = await fetchPreLogin({
        username: loginemail,
        password: '',
        captchatoken: '',
      })

      if (token) {
        const user = await fetchLogin({
          username: loginemail,
          password: EncryptData(loginpassword, token),
          captchatoken: captchaKey || '',
        })

        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('temporal', user.public_key)

        window.location.href = '/'
      }
    } catch {
      setWasError((v) => !v)
      ModalError({
        text: 'Usuario y/o contraseña incorrectos',
        confirmButtonText: 'Aceptar',
      })
    } finally {
      setLoadingFalse()
    }
  }

  const fetchPreLogin = async (body: ILogin) => {
    const { data } = await preLogin(body)
    return data
  }

  const fetchLogin = async (body: ILogin) => {
    const { data } = await Login(body)
    return data
  }

  const autoComplete = (e: any) => {
    const { name, value } = e.target
    setValue(name, value, { shouldValidate: true })
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} onChange={autoComplete}>
        <Field label="" placeholder="Email" id="loginemail" />
        <PasswordField
          id="loginpassword"
          width="250px"
          placeholder="Contraseña"
        />
        <div style={{ marginTop: '1.25rem' }}>
          <ReCatpcha
            setCheck={setIsCheck}
            setCaptchaKey={setCaptchaKey}
            wasError={wasError}
          />
        </div>
        <Submit disabled={!isValid || !isCheck}>Ingresar</Submit>
      </Form>
    </FormProvider>
  )
}
