import styled from "styled-components";

export const Wrapper = styled.div``;

export const Link = styled.button`
  cursor: pointer;
  width: 65px;
  height: 65px;
  background-color: #ffd135;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 250ms ease-in-out;
  border: 0;

  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
  @media (max-width: 768px) {
    width: 55px;
    height: 55px;

    img {
      width: 28px;
      height: 28px;
    }
  }
`;
