import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  height: 100%;
`;

export const Title = styled.h2`
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  color: #ffffff;
  font-size: 0.95rem;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.dark.main};
  display: grid;
  place-items: center;
  padding: 1.5rem;
  width: 340px;
`;
